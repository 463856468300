import { Component, OnInit, OnDestroy } from '@angular/core';
import { CoreService } from '../core.service'
import { Observable, of, pipe } from 'rxjs';

@Component({
  selector: 'app-highlights',
  templateUrl: './highlights.component.html',
  styleUrls: ['./highlights.component.scss']
})
export class HighlightsComponent implements OnInit, OnDestroy {

	//The General request lifecycles
	first_scroll_done = false;
	update_interval   = null;
	subscriptions     = new Array();
	ngOnInit() {
		this.subscriptions.push( this.core.onStreamChange.subscribe( ()=>{ this.updateHighlights() } ) );
		this.updateHighlights();
		this.update_interval = setInterval(()=>{ this.updateHighlights() }, 60000 );
	}
	ngOnDestroy() {
		for( let sub of this.subscriptions ) sub.unsubscribe();
		clearInterval( this.update_interval );
		this.first_scroll_done = false;
	}


	//Helper for the main functionality: updateHighlights
	endpointOfSrcID(id: string){
		if( this.core.sport == "nhl" )
			return `https://statsapi.web.nhl.com/api/v1/game/${id}/content`

		if( this.core.sport == "mlb" )
			return `https://statsapi.mlb.com/api/v1/game/${id}/content`
	}


	highlights: any[] = null;
	updateHighlights(){
		if( !this.core.streamCurrentGameId ) return

		let srcid = ( this.core.streamCurrentGameId.length >= 12 ) ? this.core.streamCurrentGameId.slice(0, -10) : this.core.streamCurrentGameId;

		let endpoint = this.endpointOfSrcID( srcid );

		this.core.http.get<any>( endpoint ).subscribe((res)=>{

			this.highlights = new Array();

			let Ws = new Array();
			for( let w of res.media.epg ) Ws.push( w.items );

			if( this.core.sport == "nhl" ){
				Ws.push( res.highlights.scoreboard.items );
				Ws.push( res.highlights.gameCenter.items );
				if( res.media.milestones.items )
					for( let w of res.media.milestones.items )
						if( w.highlight.id ) Ws.push( new Array(w.highlight) );
			}
			else if( this.core.sport == "mlb" ) {
				Ws.push( res.highlights.highlights.items );
			}

			//console.log(Ws)

			let temp = new Map();
			for( let w of Ws ) for( let item of w ){
				if( item.playbacks === undefined ) continue;

				temp.set(item.mediaPlaybackId, item);
				let content = new Array();

				for( let key of (item.keywords || item.keywordsAll) ) if( key.type == 'content' ) content.push( key.value );

				try      { item.image_src = item.image.cuts[7].src         } //Mlb
				catch(e) { item.image_src = item.image.cuts['640x360'].src } //NHL

				if( item.image.title.endsWith("Recap") ) content.push( "gameRecap" )
				if( item.image.title.endsWith("CondensedGame") ) content.push( "extendedHighlights" )

				temp.get(item.mediaPlaybackId)['content'] = content;
			}

			temp.forEach((k)=>{
				this.highlights.push( k )
			})

			if( !this.first_scroll_done ){
				this.first_scroll_done = true;
				setTimeout(() => {
					window.scrollTo({
						top : document.querySelector('app-highlights').parentElement.getBoundingClientRect().top + window.pageYOffset,
						behavior: 'smooth'
					})
				}, 10);
			}

		});
	}

	constructor( public core: CoreService ){}






	//HIGHLiGHTS
	streamCurrentGameId = null;
	streamCurrentHighlight = null;



	PLAYER: any = window['PLAYER'];


	showVideo( item ){
		let p = item.playbacks;
		for( let i = 0; i < p.length; i++ )
			if( p[i].name == "HTTP_CLOUD_WIRED_60" || p[i].name == "hlsCloud" ){
				this.PLAYER.start_playing( p[i].url.replace('http://', 'https://') );
				this.PLAYER.clappr.play()
				console.log("Scrolling to top!")
				window.scrollTo({ top : 0, behavior: 'smooth' })
				break;
			}

		this.core.streamCurrentHighlight = item;
		this.core.onHighlightSet.emit();
	}
}
