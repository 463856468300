<header>
	<p>
		Here you can go through the archive, see what games and feeds we have available.<br/>
		Access to the full games are limited to <span class="premium-only"><b>premium users</b> <i class="material-icons">verified_user</i></span>,
		non-premium users can still view the highlights of a game.
	</p>
	<button class="revert">
		<span class="with-sep">Current Schedule</span>
		<span *ngIf="!core.scheduleLocked">Default: Timely Relevant</span>
		<span *ngIf="core.scheduleLocked">Custom Schedule</span>
	</button>
</header>

<div class="schedule">

	<div class="team-wrapper">
		<div class="team" *ngFor="let t of core.teams" (click)="toggleTeam(t)" [class.selected]="searchTeams[t.id]">
			<img src="/sport-assets/teams/{{t.abr}}.svg" alt="">
			<span>{{ t.abr }}</span>
		</div>
	</div>

	<div class="search-wrapper">
		<button class="search" (click)="confirmSearch()">Search</button>
	</div>


	<div class="events">
		<ng-container *ngFor="let e of events">

			<div class="date" *ngIf="dateBefore.has( getEventId(e) ) && getEventDatetime(e); let dt">
				<ng-container *ngIf="core.currentDatetime.getFullYear() == dt.slice(0,4)">{{ dt | date:'MMM dd':core.tz }}</ng-container>
				<ng-container *ngIf="core.currentDatetime.getFullYear() != dt.slice(0,4)">{{ dt | date:'YYYY/MMM/dd':core.tz }}</ng-container>
			</div>

			<div *ngIf="e['_type'] == 'game'" [ngClass]="eventTags[getEventId(e)]" class="event game" (click)="core.is_premium && core.streamComponent.gameSelected(e, $event)">

				<span class="time">{{ e.start_datetime | date:'HH:mm':core.tz }} (ET)</span>

				<div class="away team">
					<img src="/sport-assets/teams/{{ e.away_abr }}.svg" />
					<span class="name">{{ e.away_abr }}</span>
				</div>

				<div class="icons">
					<span class="state">
						<i class="material-icons">alternate_email</i>
					</span>
				</div>

				<div class="home team">
					<img src="/sport-assets/teams/{{ e.home_abr }}.svg" />
					<span class="name">{{ e.home_abr }}</span>
				</div>

				<footer class="feeds">
					<ng-container *ngFor="let cid of e.content_id_list">
						<span *ngIf="core.feeds[cid]; let f" class="feed" [ngClass]="f.title.toLowerCase()">{{ f.title }}</span>
					</ng-container>
				</footer>

			</div>

			<!--div *ngIf="e['_type'] == 'feed'" [ngClass]="eventTags[getEventId(e)]" class="event feed">
				<span class="time">{{ e.event_datetime | date:'HH:mm':core.tz }} (ET)</span>

				<p>{{ e.desc }}</p>

			</div-->

		</ng-container>
	</div>

	<!--div *ngIf="!scheduleLocked" class="note">
		Schedule of the site is currently set to the games and feeds relevant to the current time,
		and as time passed, newer games/feeds will load, and older ones will go to become archived.

		So keep in mind that Choosing a <b>Custom Schedule</b> will disable the auto update as the site will be frozen in your specified time.
	</div-->
</div>


<!--div class="search-wrapper">
</div-->


<!--div class="team-wrapper" *ngIf="core.teams">
	<div class="team" *ngFor="let team of core.teams" (click)="toggleTeam( team )" [class.selected]="searchTeams.hasOwnProperty(team.id)">
		<img src="/sport-assets/teams/{{ team.abr }}.svg" alt="{{ team.abr }}">
		<p>{{ team.short }}</p>
	</div>
</div>

<button class="search" (click)="confirmSearch()" [class.disabled]="searchOrder.length <= 1 || ( gameLoading && gameCustomQuery )">
	Confirm and Search
	<i class="material-icons rotating" *ngIf="gameLoading && shouldScrollUp">settings</i>
</button>
<p class="summary" *ngIf="gameLoading && shouldScrollUp">Loading all matching games...</p>
<p class="summary" *ngIf="!gameLoading && gameCustomQuery && core.games.length == 0">
	No such match-up found in the current season!
</p>

<p class="summary" *ngIf="searchOrder.length == 1">
	Games where <span>{{ searchTeams[searchOrder[0]].abr }}</span> is playing
</p>
<p class="summary" *ngIf="searchOrder.length == 2">
	Games where
	<span>{{ searchTeams[searchOrder[0]].abr }}</span>
	is playing against
	<span>{{ searchTeams[searchOrder[1]].abr }}</span>
</p-->
