<header>
	<p>
		<span>Betting <b>Simulator</b>, Test your betting skills with <b>virtual</b> money for free!</span><br/>
		<small>You get $1,000 to start. let's see what you'll end up with at the end of the season!</small>
	</p>
</header>

<div class="virtualbet-status">
	<div class="info">
		<span>
			<b>Your Balance</b>
			<i class="material-icons">account_balance_wallet</i>
			<span class="money">${{ core.user.balance | moneyPipe }}</span>
		</span>

		<small>
			Win Sum <span class="won">${{ core.user.total_winnings | moneyPipe }}</span>, Lose Sum <span class="lost">${{ core.user.total_loss | moneyPipe }}</span>
		</small>

		<span class="max-amount">
			<b>Max amount for each pick</b>
			<span class="money">${{ maxAmount | moneyPipe }}</span>
		</span>

		<button (click)="betsimGuideShown = !betsimGuideShown">Tips and Rules</button>
	</div>
</div>

<div class="virtualbet-wrapper" [class.blur-games]="betsimGuideShown">

	<ng-container *ngFor="let g of core.games || []">
		<div *ngIf="core.user.bets[g.id] || ( core.betsimActive && g.home_odd && !cutoff.has(g.id) && g.status=='P' )"
			class="game" [class.checked]="checked[g.id]">

			<div class="datetime">
				<span>{{ g.start_datetime | date:'MM/dd HH:mm':core.tz }} (ET)</span>
			</div>
			<div class="virtualbet">

				<div class="teams" [class.locked]="g.bet_locked" [class.placed]="core.user.bets[g.id]">
					<div class="away team" (click)="gameToggleSide(g, 'A')">
						<div class="logo"><input value='away' type="image" src="/sport-assets/teams/{{ g.away_abr }}.svg"/></div>

						<div class="info">
							<p>{{ g.away_name }} <small class="text-muted">(A)</small></p>
							<p class="odd">Odd: <span class="ratio">{{ gameOddFormat(g, 'A') }}</span></p>
							<p class="result earning" *ngIf="core.user.bets[g.id] && core.user.bets[g.id].away_outcome > 0">
								Will Earn: ${{ core.user.bets[g.id].away_outcome | moneyPipe }}
								<span class="material-icons">done</span>
							</p>
							<p class="result losing" *ngIf="core.user.bets[g.id] && core.user.bets[g.id].away_outcome < 0">
								Will Lose: ${{ -core.user.bets[g.id].away_outcome | moneyPipe }}
								<span class="material-icons">close</span>
							</p>
						</div>

						<div class="checkbox" [class.selected]="checked[g.id] == 'A'"></div>
						<span class="ratio">{{ gameOddFormat(g, 'A') }}</span>
					</div>
					<div class="home team" (click)="gameToggleSide(g, 'H')">
						<div class="logo"><input value='away' type="image" src="/sport-assets/teams/{{ g.home_abr }}.svg"/></div>

						<div class="info">
							<p>{{ g.home_name }} <small class="text-muted">(H)</small></p>
							<p class="odd">Odd: <span class="ratio">{{ gameOddFormat(g, 'H') }}</span></p>
							<p class="result earning" *ngIf="core.user.bets[g.id] && core.user.bets[g.id].home_outcome > 0">
								Or Earn: ${{ core.user.bets[g.id].home_outcome | moneyPipe }}
								<span class="material-icons">done</span>
							</p>
							<p class="result losing" *ngIf="core.user.bets[g.id] && core.user.bets[g.id].home_outcome < 0">
								Or Lose: ${{ -core.user.bets[g.id].home_outcome | moneyPipe }}
								<span class="material-icons">close</span>
							</p>
						</div>

						<div class="checkbox" [class.selected]="checked[g.id] == 'H'"></div>
						<span class="ratio">{{ g.home_odd }}</span>
					</div>
				</div>

				<div class="info" [class.expanded]="checked[g.id]">

					<span>Betting On: <small class="text-muted" *ngIf="core.sport == 'nhl'">(OT included)</small></span>
					<p class="team-name" *ngIf="checked[g.id] == 'A'">{{ g.away_name }} <small>(A)</small></p>
					<p class="team-name" *ngIf="checked[g.id] == 'H'">{{ g.home_name }} <small>(H)</small></p>

					<div class="input-group">
						<span class="dollar-sign">$</span>
						<input #amoutInput name="amount" step="0.01" [min]="(+gameMinAmount(g)).toFixed(2)" [max]="maxAmount" placeholder="0.00" type="number" [(ngModel)]="amounts[g.id]"/>
						<span class="ratio" [innerText]="'×'+(+( checked[g.id] == 'H' ? g.home_odd : g.away_odd )).toFixed(2)"></span>
					</div>

					<span class="close-panel clickable" (click)="checked[g.id] = null;amounts[g.id] = null;">×</span>

					<p>
						Bet between
						<span class="min-amount" [class.invalid-reason]="gameMinAmount(g)>amounts[g.id]">${{ gameMinAmount(g) | moneyPipe }}</span>
						 ~
						<span class="max-amount" [class.invalid-reason]="amounts[g.id]>maxAmount">${{ maxAmount | moneyPipe }}</span>
					</p>

					<p class="payout">
						<span>Payout: </span>
						<span >${{ gamePayout(g) | moneyPipe }}</span>
					</p>

					<button class="place-bet" (click)="gameConfirmBet(g, amoutInput)">Place Bet</button>
				</div>

				<div class="confirm-vbet" [class.shown]="confirming.has(g.id)" [class.loading]="bettings.has(g.id)">
					<header *ngIf="amounts[g.id] > 0">
						You are betting <span class="money">${{ amounts[g.id] | moneyPipe }}</span> On team<br/>

						<span class="team" *ngIf="checked[g.id] == 'H'">{{ g.home_name }} <small>home</small></span>
						<span class="team" *ngIf="checked[g.id] == 'A'">{{ g.away_name }} <small>away</small></span>
						<br/>

						Do you want to continue?
					</header>
					<header *ngIf="!amounts[g.id] && !errors[g.id]">
						Virtual Bet placed,<br/>
						<span class="team">Calculating the possible outcomes...</span><br/>
						Please Wait!
					</header>
					<header *ngIf="!amounts[g.id] && errors[g.id]">
						Error: <br/><br/><span class="warn">{{ errors[g.id] }}</span>
					</header>
					<button (click)="gamePlaceBet(g, amoutInput)" *ngIf="!errors[g.id]" class="confirm">Confirm</button>
					<button (click)="confirming.delete(g.id); errors[g.id] = null">Back</button>
					<span class="rotating material-icons" [class.shown]="bettings.has(g.id)">autorenew</span>
				</div>
			</div>

		</div>
	</ng-container>

	<div class="empty-bet" *ngIf="core.betsimActive">
		There Are no Games to bet on right now,<br/>
		Check back at most in 24hours
	</div>

	<div class="empty-bet" *ngIf="!core.betsimActive">
		Betting Simulator is currently Disabled!
	</div>

	<footer class="guide-wrapper" [class.shown]="betsimGuideShown" #guide_panel (click)="close_guide_panel($event, guide_panel)">
		<div class="guide">
			<button class="close material-icons" (click)="betsimGuideShown = false;">close</button>
			<p class="head">Rules:</p>
			<p>
				You can bet on a game <b>only once</b>, and the amount can not be more than 3% of your balance. (Or $2 if your balance is too low)
				Also, you can only place bets for games on the same day. (After 6AM)
			</p>
			<p>
				If you manage to get your balance really low, you can ask admin to give you a refill in chat, but those who receive one will be removed from the betsim ranking for the whole season.
			</p>

			<p class="head">Tips And Important notes:</p>
			<p>
				The point of this section is to teach you how to bet so you don't get in trouble in real life.
				Remember betting on games and practices are different than gambling.
				However even betting on games can become dangerous if you don't care and don't abide by the rules.
				Sport Betting is a serious and rough business and just like any other financial market, such as the stock or insurance markets has its own rules.
			</p>
			<p>
				Never bet in real life without first building your skills in simulations.
				Learn the betting strategies, for example never bet more than 3 percent of your balance on a single game even if you have experienced winning with greater numbers.
				If you are skilled at this you can increase your money significantly by betting only 3 percent of your balance on each game.
				Never overestimate your betting skill. Make at least something between 700 to 1000 bets in simulations before deciding on betting in real life,
				and In real life never put more than a thousand dollars in your account.
				If you think you can't resist the temptation and follow the rules of betting don't even enter this business!
			</p>
			<p>
				We understand that betting business can be very exciting but remember it can be very destructive too, know your limits.
				We wish you great success and great practice,<br/><br/><b>Good luck!</b>
			</p>
		</div>
	</footer>

</div>
