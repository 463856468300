import { Component } from '@angular/core';
import { CoreService } from '../core.service'

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
	constructor( public core: CoreService ){
		if( window['CONF']['title'].includes('Nightly') )
			this.exposeCore()
	}

	exposeCore(){
		window['CONF']['core'] = this.core;
	}
}
