<header>
	<p>
		The following is the setting for the current browser you're using,<br/>
		Some features are only available on certain devices or for logged in users.<br/>
		<button class="revert" (click)="revert_settings()">Revert Settings To default</button>
	</p>
</header>

<div class="settings">

	<section>
		<header>Layout:</header>
		<button (click)="toggleSettings('use_layout_theater')" [class.active]="settings.use_layout_theater">
			<span class="toggle-button"><i class="material-icons">settings</i></span>
			<span class="text">Theater Mode</span>
		</button>
		<button (click)="toggleSettings('show_leaderboard')" [class.active]="settings.show_leaderboard">
			<span class="toggle-button"><i class="material-icons">settings</i></span>
			<span class="text">Show Leaderboard</span>
		</button>

		<header>Chat:</header>
		<button (click)="toggleSettings('chat_experimental')" [class.active]="settings.chat_experimental">
			<span class="toggle-button"><i class="material-icons">settings</i></span>
			<span class="text">Use Experimental Chat Version</span>
		</button>


		<header>Theme:</header>
		<button (click)="setTheme('default')" [class.active]="settings.theme_name=='default'">
			<span class="toggle-button"><i class="material-icons">settings</i></span>
			<span class="text">Default</span>
		</button>
		<button (click)="setTheme('queueblue')" [class.active]="settings.theme_name=='queueblue'">
			<span class="toggle-button"><i class="material-icons">settings</i></span>
			<span class="text">Queue's Blues</span>
		</button>
		<button (click)="setTheme('fabulous')" [class.active]="settings.theme_name=='fabulous'">
			<span class="toggle-button"><i class="material-icons">settings</i></span>
			<span class="text">Fabulous</span>
		</button>
		<button (click)="setTheme('blackrose')" [class.active]="settings.theme_name=='blackrose'">
			<span class="toggle-button"><i class="material-icons">settings</i></span>
			<span class="text">Black Rose</span>
		</button>
		<button (click)="setTheme('evergreen')" [class.active]="settings.theme_name=='evergreen'">
			<span class="toggle-button"><i class="material-icons">settings</i></span>
			<span class="text">Evergreen</span>
		</button>
		<button (click)="setTheme('cherry')" [class.active]="settings.theme_name=='cherry'">
			<span class="toggle-button"><i class="material-icons">settings</i></span>
			<span class="text">Cherry</span>
		</button>
		<button (click)="setTheme('flashbang')" [class.active]="settings.theme_name=='flashbang'">
			<span class="toggle-button"><i class="material-icons">settings</i></span>
			<span class="text">Flashbang</span>
		</button>
	</section>


	<section>
		<header>What do you consider spoiler:</header>
		<button (click)="toggleSettings('show_live_scores')" [class.active]="!settings.show_live_scores">
			<span class="toggle-button"><i class="material-icons">settings</i></span>
			<span class="text">Live Game's Scores</span>
		</button>
		<button (click)="toggleSettings('show_final_scores')" [class.active]="!settings.show_final_scores">
			<span class="toggle-button"><i class="material-icons">settings</i></span>
			<span class="text">Final Game's Scores</span>
		</button>

		<header>What to do with spoilers:</header>
		<button (click)="toggleSettings('delete_spoilers', true)" [class.active]="settings.delete_spoilers">
			<span class="toggle-button"><i class="material-icons">settings</i></span>
			<span class="text">Take no risks! <small>(No placeholders)</small></span>
		</button>
		<button (click)="toggleSettings('delete_spoilers', false)" [class.active]="!settings.delete_spoilers">
			<span class="toggle-button"><i class="material-icons">settings</i></span>
			<span class="text">Show spoilers under a placeholder. <small>(Hover to show)</small></span>
		</button>
	</section>

	<section>
		<header>
			Default Player:
			<small *ngIf="core.streamComponent && core.streamComponent.sp && settings.player_type!=core.streamComponent.sp.type">
				Changes will take effects after a refresh, or u can
				<button (click)="core.streamComponent.sp.change_type(settings.player_type)">Change It Now!</button>
			</small>
		</header>
		<button (click)="toggleSettings('player_type', 'shaka')" [class.active]="settings.player_type=='shaka'">
			<span class="toggle-button"><i class="material-icons">settings</i></span>
			<span class="text">Shaka Player</span>
		</button>
		<p class="option-info">
			The Newer player, visually similar to YouTube's player<br/>
			<span> - Modern and has more functionalities.</span><br/>
			<span> - Better chromecast support for premium.</span><br/>
			<span> - Our current focus when it comes to improvements.</span>
			<span *ngIf="core.sport=='nhl' && !core.is_premium">
				<br/>
				 - Please note that for NHLTV games for non-premium,
				we cannot use Shaka and the player will automatically be changed to Clappr.
			</span>
		</p>
		<button (click)="toggleSettings('player_type', 'clappr')" [class.active]="settings.player_type=='clappr'">
			<span class="toggle-button"><i class="material-icons">settings</i></span>
			<span class="text">Clappr Player</span>
		</button>
		<p class="option-info">
			The Old player we've been using since 2017, Looks homey by now!<br/>
			<span> - Had more time to settle in our system, has less bugs right now, but some of them are unfixable.</span><br/>
			<span> - Worst chromecast support for premium.</span><br/>
			<span> - We are most likley not going to fix any bugs we find that only effects clappr.</span>
		</p>
	</section>

	<section>
		<header>Auto Play:</header>
		<button (click)="toggleSettings('autoplay', 1)" [class.active]="settings.autoplay==1">
			<span class="toggle-button"><i class="material-icons">settings</i></span>
			<span class="text">Enabled</span>
		</button>
		<button (click)="toggleSettings('autoplay', 0)" [class.active]="settings.autoplay==0">
			<span class="toggle-button"><i class="material-icons">settings</i></span>
			<span class="text">Disabled</span>
		</button>

		<ng-container *ngIf="!navigator['getAutoplayPolicy']">
			<header>Auto Play Audio Policy:</header>
			<button (click)="toggleSettings('nevermute', 0)" [class.active]="settings.nevermute==0">
				<span class="toggle-button"><i class="material-icons">settings</i></span>
				<span class="text">Start with video muted</span>
			</button>
			<button (click)="toggleSettings('nevermute', 1)" [class.active]="settings.nevermute==1">
				<span class="toggle-button"><i class="material-icons">settings</i></span>
				<span class="text">
					Start with audio playing.
					<small>Your browser could block this type of playback without your explicit permission.</small>
				</span>
			</button>
		</ng-container>

		<header>Give stars to your favourite teams: <small>Effects Sorting by Relevance and Autoplay.</small> </header>
		<div class="team-wrapper">
			<div class="team" *ngFor="let t of core.teams" (click)="cycleStars(t.abr)">
				<header>
					<i class="material-icons" *ngFor="let index of core.createRange(settings.favourite_teams[t.abr] || 0)">star</i>
				</header>
				<img src="/sport-assets/teams/{{t.abr}}.svg" alt="">
				<span>{{ t.abr }}</span>
			</div>
		</div>

	</section>

	<section *ngIf="core.breakDetection">
		<header>Commercial Break Preferences:</header>
		<button (click)="toggleSettings('commercial_breaks', 1)" [class.active]="settings.commercial_breaks==1">
			<span class="toggle-button"><i class="material-icons">settings</i></span>
			<span class="text">Show Skip button on Commercial Breaks</span>
		</button>
		<button (click)="toggleSettings('commercial_breaks', 2)" [class.active]="settings.commercial_breaks==2">
			<span class="toggle-button"><i class="material-icons">settings</i></span>
			<span class="text">Auto Skip Commercial Breaks</span>
		</button>
		<button (click)="toggleSettings('commercial_breaks', 0)" [class.active]="settings.commercial_breaks==0">
			<span class="toggle-button"><i class="material-icons">settings</i></span>
			<span class="text">Ignore Commercial Breaks</span>
		</button>
	</section>

	<!--section *ngIf="navigator['share']">
		<header>Device Specific Preferences:</header>
		<button (click)="toggleSettings('show_sharing')" [class.active]="settings.show_sharing">
			<span class="toggle-button"><i class="material-icons">settings</i></span>
			<span class="text">Show "Share" Option when media is paused.</span>
		</button>
	</section-->

	<!--section>
		<header>Turbo Intensity: <small class="premium">Premium Only</small></header>
		<button (click)="toggleSettings('turbo_edge', 2)" [class.active]="settings.turbo_edge==2">
			<span class="toggle-button"><i class="material-icons">settings</i></span>
			<span class="text">Unrealistic (Average 2s Delay)</span>
		</button>
		<button (click)="toggleSettings('turbo_edge', 8)" [class.active]="settings.turbo_edge==8">
			<span class="toggle-button"><i class="material-icons">settings</i></span>
			<span class="text">Extreme (Average 8s Delay)</span>
		</button>
		<button (click)="toggleSettings('turbo_edge', 13)" [class.active]="settings.turbo_edge==13">
			<span class="toggle-button"><i class="material-icons">settings</i></span>
			<span class="text">Normal (Average 13s Delay)</span>
		</button>
		<p>
			The turbo feature, is in nature unstable, and how much you can push it, depends on your internet connection (ping and speed), your device, and even the stream itself.
			The more you push it, the more likeley it is to <b>start buffering and set the stream back</b> to a normal delay level of around 30s,
			so experiment with it and find the best for your case.<br/>
			Don't forget to disable and re-enable the turbo button after changing the setting.
		</p>
	</section-->

	<!--
	Auto Play the newest stream on load.<br/>
	Preserve current volume<br/><br/>

	Stream Preference:<br/>
		Do nothing, Show the raw stream.<br/>
		Prevent the raw stream from going into "trimmed mode" (NHL only)<br/>
		Show the trimmed66 version when available, (all Commercials are cut after the stream is over)<br/>
		Show 5 minutes slide version of stream (not for IOS)<br/><br/>
	-->

</div>
