import { Component } from '@angular/core';
import { CoreService } from '../core.service'

@Component({
	selector: 'app-devlog',
	templateUrl: './devlog.component.html',
	styleUrls: ['./devlog.component.scss']
})
export class DevlogComponent {
	constructor( public core: CoreService ){}

	get nav() { return navigator; }

	logs: string[] = [];
	ngOnInit() {
		this.ios_version = this.iOSversion();

		navigator.serviceWorker.addEventListener('message', (event) => {
			let url = event.data.url;
			//if( url.indexOf("nhl24network") == -1 )
			this.logs.push( url );

			if( this.logs.length == 0 )
				this.logs.push( "Got Some Other Stuff though..." );
		});
	}

	loadJunk(){
		this.core.streamComponent.spAct("start", "/video.m3u8")
	}

	ios_version = null;
	iOSversion() {
		if (/iP(hone|od|ad)/.test(navigator.platform)) {
			// supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
			var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
			return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || '0', 10)];
		}
		return 'not-ios'
	}


}
