<header>
	<p>
		This is where you can read about our site's upcomming features,
		and find the logs of the past things that had happened until now.<br/>
		- Written By Queue A
		<span (click)="loadJunk()" style="color: red; cursor: pointer;">Load Junk</span>
	</p>
</header>

<div class="notes">
	<p class="entry" *ngIf="logs.length">
		<span class="title">Service Worker Logs:</span><br/>
		<span *ngFor="let log of logs">{{ log }}<br/></span>
	</p>

	<p class="entry">
		<span class="title">Upcomming/Drafted Features</span><br/>
		<span>
			[ ] Add discord sign-up/login to the site <br/>
			[ ] Add the option to have multiple login options for a single account, instead of each login option creating/loging in to different accounts.<br/>
			[ ] Issues with old iphones (You're using {{ ios_version }})<br/>
			[ ] UA: {{ nav.userAgent }}<br/>
			[ ] AV: {{ nav.appVersion }}<br/>
		</span>

		<span class="title">Known Bugs</span><br/>
		<span>
			[ ] Edge has a problem and CCs can't be disabled. <br/>
		</span>

	</p>

	<p class="entry">
		<span class="title">22 Aug 2020</span><br/>
		<span>
			3 More days and we could say I totally forgot to update this section for a year! but not this year my friend, not this year!
			I'll try to list all the changes we did in the past year in here. those I remember of course!
		</span><br/><br/>
		<span>
			We can Detect breaks and allow skipping on them. This feature sometimes gets buggy on NHL replays, but only sometimes.<br/>
		</span><br/>
		<span>
			Expanding on the feature above, there is now the option to check all available feeds to see if they are on break or not,
			helping people find something to watch while their favorite stream is on a break.
		</span><br/>
		<span>
			Lots of optimizations for iOS/PS4 users, they can now watch the games quite easily, with no lags. Or so I'm told since I don't own an iOS, and I never will! NEVER!
		</span><br/>
		<span>
			We've gone on the deep end when it comes to backend developement, as the time of writing, we have 11 Servers! 2 Giant dedicated ones, and 9 Mirror VPSs.
			So if we go down under pressure, it means either We are getting too popular, or I've goofed the whole system again! usually the latter.
		</span><br/>
		<span>
			I tried my best at supporting Game2's of MLB, it's a real issue since they recycle their UIDs for postponed games and it just messes everything up in our system.
			If it breaks again, I try to patch it again, until it's finally fixed.
		</span><br/>
		<span>
			We also added a "settings" section, so you can customize the site to fit how you like it. More configs will be added later.
		</span><br/>
		<span>
			Sadly, The download section could not handle the whole pandemic thing and the vast amounts of redesigning I did to keep things afloat.
			I'm trying my best to get it back, but it might take a while.
		</span><br/>
		<span>
			We added a bunch of commands and limitations to the chat. you should expect to have a better experience with the chat now.
		</span><br/>
	</p>

	<p class="entry">
		<span class="title">25 Aug 2019</span><br/>
		<span>
			MLB network was added. (IOS not supported)
		</span><br/>
		<span>
			New more fair ranking system created.
		</span><br/>
		<span>
			Top 20 Users will be shown in the main site
		</span><br/>
		<span>
			A sign-up issue was fixed that effected all new users for about a week.
		</span><br/>
	</p>

	<p class="entry">
		<span class="title">10 May 2019</span><br/>
		<span>
			Download Code was finished!
		</span><br/>
		<span>
			The Interface was redesigned and 4 slots where added on top of the site, 3 might be used for ads in the future.
		</span><br/>

		<span class="title">Improvements / New Features</span><br/>
		<span>
			[X] Setup Download options with removed CommercialBreaks<br/>
		</span>
		<span>
			[X] Highlights will now load new content automatically<br/>
		</span>

	</p>



	<p class="entry">
		<span class="title">2 May 2019</span><br/>
		<span>
			The iOS server's code was changed to my heart's content! we're now using "ASYNCIO" and "aiohttp" to mirror what iOS can't do itself, and it's pretty sleek! <br/>
			<small>
				Since we had a bunch of people asking, this is whats iOS lacking:
				<a href="https://developer.mozilla.org/en-US/docs/Web/API/MediaSource#Browser_compatibility" target="_blank">MediaSource</a>
				<br/>
			</small>
		</span>
		<span>
			30% of our new download code has been completed.<br/>
		</span>
		<span>
			Streams while paused, will show a button that allows it to be shared if your
				<a href="https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share#Browser_compatibility" target="_blank">browser supports sharing</a>.
			(Mostly on Mobile phones)<br/>
		</span>
		<span>
			MLB streams have been fully automated, we shouldn't have any more problems with missing streams<br/>
			Sadly, NHL stream are still semi-automatic, there is a chance that we need to manually add missing streams every now and then.
		</span><br/>
		<span>
			A small easter egg was added for those who post under the name "Z|ANON" and their egos need a bit of further boosting! ;3
		</span>

		<br/>
		<span class="title">Fixed Bugs</span><br/>
		<span>
			[X] Sometimes it takes a while in baseball for the stream to first appear. <br/>
		</span>
		<span>
			[X] Chat has a glitch which clears the draft message every now and then! <br/>
		</span>

	</p>

	<p class="entry">
		<span class="title">10 April 2019</span><br/>
		<span>
			Updated the site with a new routing technology that allows the user to navigate through pages without disturbing the stream.<br/>
		</span>
		<span>
			4 New Pages added, but 2 are still under-construction.<br/>
		</span>
		<span>
			Highlights and Updates (this section) have been completed.<br/>
		</span>
	</p>


	<p class="entry">
		<span class="title">7 April 2019</span><br/>
		<span>
			<b>Baseball (MLB)</b> has been added to the site with the url: <a href="https://mlb66.ir">mlb66.ir</a><br/>
			Prior to this, only hockey was supported. (at <a href="https://nhl66.ir">nhl66.ir</a>)
		</span>
	</p>

	<p class="entry">
		<span class="title">Older Entries</span><br/>
		<span>
			iOS servers were updated, and the code where optimized to an insane degree,
			iOS users alongside other users should experience a smooth and fast stream.
		</span><br/>

		<span>
			More testing and researching has proven that sadly, <b>Chrome Cast</b> cannot be supported natively,
			meaning that only desktop users that have the "Cast The Tab" option (in chrome) are able to use Chrome Cast, and
			Supporting this feature natively costs enormous amounts of resources that are way beyond us for the time being...
		</span>
		<small>
			If you're curious as why this is, the main reason is that in order to support HLS with adaptive streaming on Chrome Cast,
			One has to write an "<a href="https://store.google.com/product/chromecast_apps">Chrome Cast Application</a>" which needs an entire new engine,
			coding such a thing is specially out of reach because it has to comply with <i>Google's policy</i>, which is impossible with the nature of our site!
		</small><br/>

		<span>
			We integrated our Site with our Chatbox, doing so helped everyone have a cleaner experience with the site.
			Anonymous users have been assigned a random username (adj+noun) so we may have less inappropriate username!
			<small>although random usernames aren't always the most family friendly of names! but they are too amusing to be filtered! sry RectalEnlargement!</small>
		</span><br/>

		<span>
			Due to Heavy Load, and changing of our servers, we had to temporarily disable our "Download" and "Highlights" Section,
			They will be back as soon as possible.
		</span>

	</p>
</div>
