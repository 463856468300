<header *ngIf="sp">
	<div class="title" *ngIf="core.feeds[sp.feed_id]; let f">
		<div class="icon-group" [ngClass]="'status_' + f.status.toLowerCase()">
			<i class="container material-icons">tv</i>
			<i class="material-icons" [innerText]="core.feedStatusToIcon[f.status]"></i>
		</div>

		<div class="game" *ngIf="core.id2games[sp.game_id]; let g">
			<img src="/sport-assets/teams/{{ g.away_abr }}.svg" />
			<span class="name">{{ g.away_abr }}</span>
			<i class="material-icons at">alternate_email</i>
			<img src="/sport-assets/teams/{{ g.home_abr }}.svg" />
			<span class="name">{{ g.home_abr }}</span>
			<span class="feed">{{ f.title }}</span>
		</div>
	</div>

	<div class="title">
		<div class="icon-group live">
			<i class="container material-icons">tv</i>
		</div>

		<span> LIVE TV / <b>REPLAYS</b> </span>
	</div>

	<button class="selector" (click)="(streamMenuShown=!streamMenuShown) && (linkMenuShown=false)">
		<span>Select Game</span>
		<i class="material-icons" [innerText]="streamMenuShown ? 'close' : 'expand_more'"></i>
	</button>
</header>

<section class="stream-screen-wrapper">
	<img class="ratio" [src]="core.getPlacehoderVideo()" alt=""/>

	<div class="stream-screen">
		<app-player *ngFor="let conf of core.createRange(playerCount); let playerId=index" [playerId]="playerId" [streamComponent]="this"></app-player>
	</div>

	<div class="stream-selector overlay" [class.shown]="streamMenuShown">

		<div class="cards" [class.grid]="streamGridView" [class.list]="!streamGridView">
			<div class="row-sep tip view-select">
				<div>
					View
					<button (click)="(streamGridView = false) || reorderStreams()" [class.active]="!streamGridView">List <i class="material-icons">view_list</i></button>
					<button (click)="(streamGridView = true)  && reorderStreams()"  [class.active]="streamGridView">Grid <i class="material-icons">grid_view</i></button>
				</div>

				<div>
					Sort
					<button (click)="(streamSortByTime = true)  && reorderStreams()"  [class.active]="streamSortByTime">Time <i class="material-icons">schedule</i></button>
					<button (click)="(streamSortByTime = false) || reorderStreams()" [class.active]="!streamSortByTime">Relevance <i class="material-icons">favorite</i></button>
					<button (click)="core.redirectTo('/settings', true)">Customize <i class="material-icons">settings</i></button>
				</div>
			</div>

			<ng-container *ngFor="let g of core.games">
				<div class="card game" [class.live]="g.status == 'I'" [class.pregame]="g.status == 'P'" [style.order]="gameidToOrder[g.id]"
					*ngIf="orderedFeedsInGame[g.id]; let fs" (click)="gameSelected(g, $event)">

					<span class="datetime">
						<ng-container *ngIf="core.currentDatetime.getFullYear() == g.start_datetime.slice(0,4)">{{ g.start_datetime | date:'MM/dd HH:mm':core.tz }} (ET)</ng-container>
						<ng-container *ngIf="core.currentDatetime.getFullYear() != g.start_datetime.slice(0,4)">{{ g.start_datetime | date:'YYYY/MM/dd HH:mm':core.tz }}</ng-container>
					</span>

					<div class="away">
						<header><i class="material-icons" *ngFor="let index of core.createRange(core.settings.favourite_teams[g.away_abr] || 0)">star</i></header>
						<img src="/sport-assets/teams/{{ g.away_abr }}.svg" />
						<span class="name">{{ g.away_abr }}</span>
					</div>

					<div class="icons">
						<span class="state">
							<i class="material-icons">alternate_email</i>
						</span>

						<span class="feeds" *ngIf="streamGridView">
							<i class="material-icons" *ngFor="let f of fs" [innerText]="core.feedStatusToIcon[f.status]"></i>
						</span>
					</div>

					<div class="home">
						<header><i class="material-icons" *ngFor="let index of core.createRange(core.settings.favourite_teams[g.home_abr] || 0)">star</i></header>
						<img src="/sport-assets/teams/{{ g.home_abr }}.svg" />
						<span class="name">{{ g.home_abr }}</span>
					</div>

					<footer class="tags">
						<span *ngFor="let tag of gameidToTags[ g.id ]" [ngClass]="'tag-' + tag">{{ tagClassToText[tag] }}</span>
					</footer>

					<div class="feeds" *ngIf="!streamGridView">
						<button *ngFor="let f of fs">
							<span>{{ f.title }}</span>
							<i class="material-icons" [innerText]="core.feedStatusToIcon[f.status]"></i>
						</button>
					</div>

				</div>
			</ng-container>
		</div>

		<footer>
			<span class="tip" *ngIf="!core.scheduleLocked">To view more games, use the Time-Machine feature!</span>
			<span class="tip" *ngIf="core.scheduleLocked">You're using a fixed schedule: <button (click)="(core.scheduleLocked=false) || core.syncState()">Revert?</button></span>
		</footer>

	</div>
</section>

<footer>
	<aside class="layout">
	</aside>
	<div class="controls" *ngIf="sp">

		<div class="feeds" *ngIf="sp.game && orderedFeedsInGame[sp.game.id]; let fs">
			<button *ngFor="let f of fs"
				class="feed" [class.active]="sp.feed.id == f.id"
				(click)="gameSelected(sp.game, f.title)">
				<i class="material-icons" [innerText]="sp.feed.id == f.id ? 'smart_display' : 'play_arrow'"></i>
				<span>
					{{ f.title }}
					<small>{{ f.titles }}</small>
				</span>
			</button>
		</div>

		<button class="stop" (click)="sp.stop()" (dblclick)="sp.destroy()"><i class="material-icons">stop</i></button>
	</div>
</footer>
