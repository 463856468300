import { Component, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { CoreService } from '../core.service'

@Component({
	selector: 'app-simulator',
	templateUrl: './simulator.component.html',
	styleUrls: ['./simulator.component.scss']
})
export class SimulatorComponent implements OnInit, OnDestroy {

	//Betsim
	amounts: any = {}; //game_id -> number (-100 -> 100)
	checked: any = {}; //game_id -> 'A' or 'H'
	errors:  any = {}; //game_id -> msg

	cutoff:  Set<number> = new Set();    //game_id of no longer betable games...
	confirming: Set<number> = new Set(); //game_ids of games being OKed.
	bettings:   Set<number> = new Set(); //game_ids of bets being sent to the server
	beating: Set<number> = new Set(); //game_id

	get maxAmount(): number {
		let balance = this.core.user.balance;
		return +Math.max(+balance*0.03, Math.min(2,+balance)).toFixed(2)
	}


	gameToggleSide( game, side ) {
		if( this.checked[game.id] == side )
			this.checked[game.id] = null;
		else
			this.checked[game.id] = side;
	}
	gameOddFormat( game, side ){
		if( side == "A" )
			return game.away_odd

		if( side == "H" )
			return game.home_odd
	}
	gameMinAmount( game ): number {
		let ratio = ( this.checked[game.id] == 'A' ? game.away_odd : game.home_odd );
		if( ratio > 2 ) return 1;
		return 1 / ( ratio - 1 )
	}
	gamePayout( game ): number {
		if( !this.amounts[game.id] )
			return 0

		return this.amounts[game.id] * ((this.checked[game.id] == 'A' ) ? game.away_odd : game.home_odd)
	}
	gameConfirmBet( game, node ): void {
		if( !node.checkValidity() || !this.amounts[game.id] )
			return

		this.confirming.add(game.id)
	}
	gamePlaceBet( game, node ): void {

		if( this.bettings.has(game.id) ) return;

		if( !this.core.auth ){
			this.core.onAuthRequired.emit(null);
			return;
		}

		this.bettings.add(game.id)

		this.core.http.post<any>(
			`https://api.${this.core.top_domain}/api/prediction/place_bet`,
			{ 'game_id': game.id, 'on' : this.checked[game.id], 'home_odd' : game.home_odd, 'away_odd' : game.away_odd, 'amount' : this.amounts[game.id] },
			{ withCredentials: true }
		).subscribe( (success) => {

			delete this.amounts[game.id];
			delete this.checked[game.id];
			this.bettings.delete(game.id)


			if( success['status'] == 'error' )
				this.errors[game.id] = success['message']
			else if( success == true )
				this.core.getMe();

		}, (err)=>{
			this.bettings.delete(game.id)
			this.errors[game.id] = err.statusText;
		});
	}

	//Simple Template Logic:
	betsimGuideShown: boolean = false;
	close_guide_panel(event, node): void {
		if( event.target == node ) this.betsimGuideShown = false;
	}


	constructor( public core: CoreService ){}

	subscriptions = new Array();
	ngOnDestroy() {
		for( let sub of this.subscriptions ) sub.unsubscribe();
	}

	ngOnInit() {

		this.subscriptions.push(
			this.core.onClockTick.subscribe(()=>{
				this.cutoff.clear();
				if( this.core.games )
					for( let g of this.core.games )
						if( new Date(new Date().getTime() + this.core.serverTimeDiff) > new Date(new Date(g.start_datetime).getTime() - 300000) )
							this.cutoff.add( g.id );
			})
		);

	}



}
