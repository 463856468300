import { Component, HostBinding, OnInit, OnDestroy }      from '@angular/core';
import { CoreService } from '../core.service'

@Component({
	selector: 'app-timemachine',
	templateUrl: './timemachine.component.html',
	styleUrls: ['./timemachine.component.scss']
})
export class TimemachineComponent implements OnInit, OnDestroy {

	dateBefore: Set<string> = new Set(); //event_type|event_id s...
	eventTags: any = {}; // { eventid -> ngClass }
	events: any[] = []; // game/links[]


	getEventId(e){
		return `${e['_type']}|${e['id']}`
	}
	getEventDatetime(e){
		return e['event_datetime'] || e['start_datetime']
	}
	getEventDate(e){
		return this.core.convertDate( this.getEventDatetime(e) )
	}
	createSchedule( games, links ){


		this.eventTags = {};
		this.events = [];

		for( let g of games ){
			g['_type'] = "game";
			this.events.push( g )

			this.eventTags[this.getEventId(g)] = {}
		}

		for( let cid in this.core.feeds ){
			let f = this.core.feeds[cid];
			let fclass = {};

			if( f.status != "P" )
				fclass["video-exists"] = true;

			if( f.status == "L" )
				fclass["live"] = true;

			if( f.game_id ){
				let eid = "game|" + f.game_id;
				this.eventTags[eid] = { ...(this.eventTags[eid] || {}), ...fclass };
			} else
				this.events.push( f )

			this.eventTags[this.getEventId(f)] = fclass;
		}

		this.events.sort((e1, e2)=>{
			let d1 = this.getEventDate(e1)
			let d2 = this.getEventDate(e2)

			//If only javascript knew how to sort number[]!
			if ( d1.getFullYear() < d2.getFullYear() ) return +1;
			if ( d1.getFullYear() > d2.getFullYear() ) return -1;

			if ( d1.getMonth() < d2.getMonth() ) return +1;
			if ( d1.getMonth() > d2.getMonth() ) return -1;

			if ( d1.getDate() < d2.getDate() ) return +1;
			if ( d1.getDate() > d2.getDate() ) return -1;

			//Now reverse!
			if ( d1.getHours() < d2.getHours() ) return -1;
			if ( d1.getHours() > d2.getHours() ) return +1;

			if ( d1.getMinutes() < d2.getMinutes() ) return -1;
			if ( d1.getMinutes() > d2.getMinutes() ) return +1;

			if ( d1.getSeconds() < d2.getSeconds() ) return -1;
			if ( d1.getSeconds() > d2.getSeconds() ) return +1;

			return 0;
		})

		this.dateBefore.clear();
		let last_day = null;
		for( let e of this.events ){
			let day  = this.getEventDate( e );
			let date = `${day.getFullYear()}-${day.getMonth()}-${day.getDate()}`;

			if( last_day != date ){
				this.dateBefore.add( this.getEventId(e) )
				last_day = date;
			}
		}



	}

	searchOrder: number[] = [];
	searchTeams: any = {};
	toggleTeam( team ) {
		if( this.searchTeams[team.id] ){
			delete this.searchTeams[team.id];
			this.searchOrder = this.searchOrder.filter( e => e != team.id );
			return;
		}

		if( this.searchOrder.length >= 2 ){
			let id = this.searchOrder[0];
			this.searchOrder = this.searchOrder.slice(1);
			delete this.searchTeams[id];
		}

		this.searchTeams[team.id] = team;
		this.searchOrder.push(team.id);
	}
	confirmSearch(){
		/*let t1t2 = Object.entries(this.core.settings.favourite_teams).sort((a,b)=>{ return +a[1] - +b[1] }).slice(-2);
		let t1 = this.core.abr2teams[t1t2[0][0]].id;
		let t2 = this.core.abr2teams[t1t2[1][0]].id;*/

		this.core.http.get(`https://api.${this.core.top_domain}/api/sport/query_games?teams=${this.searchOrder.join('-')}` ).subscribe(resp=>{
			this.core.assignSchedule( resp['games'], resp['links'] );
			this.core.scheduleLocked = true;
			this.core.onGamesChange.emit(null);
		})


	}

	/*
	searchTeamRelations = ["@","&","W","L"]; //["At", "Played against", "Won against", "Lost to"];
	searchTeamRelationsAlias = {
		"@" :"@",
		"at":"@",

		"&":"&",
		"played against":"&",
		"against":"&",
		"with":"&",
		"and":"&",

		"won":"W",
		"won against":"W",
		"won facing":"W",
		"prevailed upon":"W", //IDK why!

		"lost":"L",
		"lost to":"L",
		"lost against":"L",
		"lost facing":"L",
		"perished facing":"L", //Also IDK why!
	};

	searchTeams: any = []; //team_id, [team_id, "-", team_id]
	searchTime: any = null; // season date, [specific_date], [start_range, end_range]
	searchSpecials: boolean = null; //null, false, true
	*/

	constructor( public core: CoreService ){}

	subscriptions     = new Array();
	ngOnInit() {
		this.subscriptions.push(
			this.core.onGamesChange.subscribe(
				()=>this.createSchedule(this.core.games, this.core.links)
			)
		);

		if( this.core.links && this.core.games )
			this.createSchedule(this.core.games, this.core.links)
	}
	ngOnDestroy() {
		for( let sub of this.subscriptions ) sub.unsubscribe();
		this.core.scheduleLocked = false;
	}



	/*
	gameCustomQuery: any = null;
	gameLoading: any = false;
	range_message = {
		"NHL": ["since 2017", "since 2017"],
		"MLB": ["since 2019", "since 2019"],
		"NFL": ["since 2012",  "since 2012"],
		"NBA": ["this season", "from the Current Season"],
	}[window['SPORT']]


	searchOrder: number[] = [];
	searchTeams: any = {};
	toggleTeam( team ) {
		if( this.searchTeams[team.id] ){
			delete this.searchTeams[team.id];
			this.searchOrder = this.searchOrder.filter( e => e != team.id );
			return;
		}

		if( this.searchOrder.length >= 2 ){
			let id = this.searchOrder[0];
			this.searchOrder = this.searchOrder.slice(1);
			delete this.searchTeams[id];
		}

		this.searchTeams[team.id] = team;
		this.searchOrder.push(team.id);
	}

	confirmSearch(){
		if( this.searchOrder.length <= 1 ) return;
		//this.core.gameCustomQuery = '?teams=' + this.searchOrder.join(',');
		//this.core.getSchedule()
		this.shouldScrollUp = true;
	}

	revertSearch(){
		//if( !this.core.gameCustomQuery ) return;

		//this.core.gameCustomQuery = '';
		//this.core.getSchedule()
	}

	public get shouldScrollUp() : boolean {
		return this.core.streamComponent.shouldScrollUp
	}
	public set shouldScrollUp(v : boolean) {
		this.core.streamComponent.shouldScrollUp = v;
	}
	*/

}
