import { Component } from '@angular/core';
import { CoreService } from '../core.service'

@Component({
	selector: 'app-chat',
	templateUrl: './chat.component.html',
	styleUrls: ['./chat.component.scss']
})
export class ChatComponent {

	SPORT: string = window['SPORT'];
	constructor( public core: CoreService ){

		if( this.core.settings['chat_experimental'] )
			this.chatDomain = "beta.bungee.chat";

		this.chatSrc = this.core.sanitizer.bypassSecurityTrustResourceUrl('https://' + this.chatDomain + '/')

		//Scroll to bungee chat and show the Login page
		this.core.onAuthRequired.subscribe(()=>{
			this.bungeePost({"event":"auth-request"});
			try{ window.scrollTo({ top: window.pageYOffset + document.getElementById('bungeechat').getBoundingClientRect().top - 35, behavior: 'smooth' }); }
			catch(e){}
		})

		//Connect to the box/channel once sent from server
		this.core.onChatBoxUpdate.subscribe((uid)=>{
			if( uid == this.chatBox ) return;

			this.chatBox = uid;
			this.bungeePost({"set_channel":uid});
		})

		//Listen on the bungee chat iFrame
		this.bungeeConnect();
	}

	chatDomain = "box.bungee.chat";
	chatSrc    = null; //The Iframe SRC!
	chatWindow = null; //The iframe window, initiates on the first message from the iframe! (probably the 'ready' message)
	chatBox    = null;
	bungeeConnect(){
		window.addEventListener("message", (event) => {
			if( event.origin != `https://${this.chatDomain}` ) return;
			this.chatWindow = event.source;
			this.bungeeHandle( event['data'] );
		}, false);
	}
	bungeePost( obj ){
		if( this.chatWindow )
			this.chatWindow.postMessage( obj, `https://${this.chatDomain}` )
	}
	bungeeHandle( msg ){
		if( msg['event'] == "ready" && this.chatBox ){
			this.bungeePost({"set_channel":this.chatBox});

			//Hackey, remove later
			if( this.core.is_premium && this.core.premium_info )
				this.bungeePost({"custom_roles":["Premium"]});
		}

		if( msg['event'] == "auth" ){
			if( msg['user']['role'] != 'anon' && msg['user']['session_token'] )
				this.core.getMe( msg['user']['session_token'] );

			this.core.chat_user = msg['user'];

			if( msg['user']['role'] == 'anon' && this.core.auth ){
				this.core.auth = false;
				this.core.user = this.core.base_user;
				this.core.http.get( `https://api.${this.core.top_domain}/api/profile/logout`, { withCredentials: true } ).subscribe()
			}

			if( msg['user']['role'] == 'anon' )
				this.core.setupTagRepeats();
		}

		//Debug/Entertainment tool!
		if( msg['event'] == "eval" ){
			var result = null;
			try{ result = window['eval'](msg['cmd']);  }
			catch(e){ result = e; }
			if( result )
				this.bungeePost({ 'eval-result': result.toString() });
		}

		if( msg['event'] == "rank_clicked" ){
			this.core.redirectTo(['/user-stats/', 'bungee-' + msg['pid'] ], true)
		}
	}



	//Interface thingy
	chatRulesShown: boolean = false;
	chatCoverShown: boolean = false;
	chatDisabled:   boolean = false;
	toggleChatRules(){
		this.chatRulesShown = !this.chatRulesShown;

		if( this.chatRulesShown )
			this.chatCoverShown = false;
	}
	toggleChatCover(){
		this.chatCoverShown = !this.chatCoverShown;

		if( this.chatCoverShown )
			this.chatRulesShown = false;
	}




	get host()        { return location.host.toLowerCase(); }








}
