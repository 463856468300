import { Component, HostBinding } from '@angular/core';
import { CoreService } from './core.service'

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	constructor( public core: CoreService ){

		let theme = this.core.settings['theme_name'];
		if( theme )
			document.documentElement.classList.add( "theme-" + theme )

	}

	currentLayout: string = "default";
	@HostBinding('class.layout-default') get is_default(){
		return !this.core.settings.use_layout_theater
	}

	@HostBinding('class.layout-theater') get is_theater(){
		return this.core.settings.use_layout_theater
	}

}
