import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DevlogComponent } from './devlog/devlog.component';
import { HighlightsComponent } from './highlights/highlights.component';
import { SettingsComponent } from './settings/settings.component';
import { SimulatorComponent } from './simulator/simulator.component';
import { TimemachineComponent } from './timemachine/timemachine.component';
import { ScoreboardsComponent } from './scoreboards/scoreboards.component';

const routes: Routes = [
	{ path: 'devlog',       component: DevlogComponent        },
	{ path: 'highlights',   component: HighlightsComponent    },
	{ path: '',             component: SimulatorComponent     },	{ path: 'simulator', redirectTo: '/', pathMatch: 'full' },
	{ path: 'time-machine', component: TimemachineComponent   },

	{ path: 'top-rankings',    component: ScoreboardsComponent },	{ path: 'stats-for', redirectTo: '/top-rankings', pathMatch: 'full' },
	{ path: 'user-stats/:uid', component: ScoreboardsComponent }, //{ path: 'user-stats/:uid', component: ScoreboardsComponent },
	{ path: 'settings',        component: SettingsComponent },

	{ path: '**', component: SimulatorComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
