<button class="settings" (click)="core.redirectTo('/settings', true)">
	<i class="material-icons">settings</i>
	<small>Device</small>
	<small>Settings</small>
</button>

<button *ngIf="core.auth" class="profile" (click)="core.redirectTo('/top-rankings', true)">
	<img [src]="core.user.avatar" alt=""/>
	<small>Stats</small>
	<i *ngIf="core.is_premium" class="premium material-icons">verified</i>
</button>

<button *ngIf="!core.auth" class="profile" (click)="core.onAuthRequired.emit(null)">
	<img [src]="core.user.avatar" alt=""/>
	<small>Login</small>
</button>

<aside>
	<div class="premium-info-wrapper" *ngIf="core.premium_info">
		<p *ngIf="core.premium_info" (click)="(core.premium_sig === false) && core.maintain_premium_sig(true)">
			<span>{{ core.premium_info['credit']['entitlement']['name'] }} Package
				<i *ngIf="core.premium_sig" class="material-icons">verified_user</i>
				<i *ngIf="core.premium_sig === null" class="material-icons rotating">sync</i>
				<i *ngIf="core.premium_sig === false" class="material-icons error">sync_problem</i>
			</span><br/>

			<span *ngIf="core.premium_info['credit']['extra_time']==0" class="premium-expiration">Expiration Date: {{ core.premium_info['credit']['expiration_date'] }}</span>
			<span *ngIf="core.premium_info['credit']['extra_time']>0" class="premium-expiration">Transaction Pending Confirmation...</span>
		</p>

		<div class="deactivate-button">
			<i class="material-icons">workspace_premium</i>
			<div class="code-seg">&#8226;&#8226;&#8226;&#8226;</div><span>-</span>
			<div class="code-seg">&#8226;&#8226;&#8226;&#8226;</div><span>-</span>
			<div class="code-seg">&#8226;&#8226;&#8226;&#8226;</div><span>-</span>
			<div class="code-seg">&#8226;&#8226;&#8226;&#8226;</div>
			<button (click)="premiumLogoutConfirming=true"><i class="material-icons">power_settings_new</i> Premium</button>
		</div>
	</div>

	<div class="premium-code-wrapper" *ngIf="!core.is_premium">
		<p class="message" *ngIf="premiumError" (click)="premiumError=null" [innerHTML]="premiumError"></p>
		<p class="message" *ngIf="!premiumError">
			<span>
				Want a Premium Code?<br/>
				Purchase one
				<a class='premium-purchase' href='https://account24network.com/' target='_blank'>from <b>Here</b>!</a>
			</span>
		</p>
		<div class="activate-button">
			<i class="material-icons">whatshot</i>
			<input type="tel" maxlength="4" placeholder="" #code1 (paste)="premiumPaste($event)" (keydown)="premiumKeyDown($event, null,  code2)"/><span>-</span>
			<input type="tel" maxlength="4" placeholder="" #code2 (paste)="premiumPaste($event)" (keydown)="premiumKeyDown($event, code1, code3)"/><span>-</span>
			<input type="tel" maxlength="4" placeholder="" #code3 (paste)="premiumPaste($event)" (keydown)="premiumKeyDown($event, code2, code4)"/><span>-</span>
			<input type="tel" maxlength="4" placeholder="" #code4 (paste)="premiumPaste($event)" (keydown)="premiumKeyDown($event, code3, null )"/>
			<button (click)="premiumGo()">Premium</button>
		</div>
	</div>

	<div *ngIf="core.is_premium" class="premium-logout-panel" #premium_logout_panel [class.active]="premiumLogoutConfirming" (click)="close_premium_logout_panel($event, premium_logout_panel)">
		<div class="inner-modal premium-logout">
			<button class="close material-icons" (click)="premiumLogoutConfirming=false;">close</button>
			<span class="title">Are you sure you want to log out from the Premium Site?</span>
			<span class="note" *ngIf="core.auth">
				If you only want to log out of your user account (username: {{ core.chat_user && core.chat_user.username }}),
				without exiting the premium site, you can do so from inside the chat.<br/>
				Simply click the "My Profile" button, and then choose "Log Out".
			</span>
			<span class="note">If you still want to remove your premium code from this device, Press the button bellow.</span>
			<button class="logout" (click)="core.premium_logout()">Log Out of Premium</button>
			<small class="note">Make sure you know your premium code in case you want to log back in:<br/><b>{{ core.premium_info['code'] }}</b></small>
		</div>
	</div>
</aside>

<div class="leaderboard" *ngIf="!leaderboardClosed && core.settings.show_leaderboard && (core.topPUsers.length + core.topSUsers.length > 0)">

	<div class="reverse-motion-screen"    [style.animation-duration.s]="mover.scrollWidth / (fastForwarding ? 200 : 20)">
		<div class="motion-screen" #mover [style.animation-duration.s]="mover.scrollWidth / (fastForwarding ? 200 : 20)" [class.rev]="leaderboardReversed">

			<section>
				<h1>Prediction Leaderboard</h1>
				<div class="user prediction" *ngFor="let u of core.topPUsers">
					<span class="info">
						<span class="rank">{{ u.prediction_rank }}</span>
						<i class="material-icons">{{ core.rank_of(u).icon }}</i>
						<span class="title"> {{ core.rank_of(u).title }}</span>
					</span>
					<small class="stats">
						<span class="name"><img *ngIf="u.avatar" [src]="u.avatar"/>{{ u.username }}</span>
						<span class="percentage">
							<span class="good-text">{{ u.total_right_predictions }}R</span><span class="bad-text">{{ u.total_wrong_predictions }}W</span>:{{ 100 * u.total_right_predictions / ( u.total_right_predictions + u.total_wrong_predictions ) | number:'0.0-2' }}%
						</span>
					</small>
				</div>
			</section>
			<section>
				<h1>Betting Simulator</h1>
				<div class="user simulator" *ngFor="let u of core.topSUsers; let i = index">
					<span class="rank" [ngClass]="'index_' + i">{{ u.bet_rank }}</span>
					<span class="name"><img *ngIf="u.avatar" [src]="u.avatar"/>{{ u.username }}</span>
					<span class="balance">${{ +u.balance + +u.total_unsettled | moneyPipe }}</span>
				</div>
			</section>

		</div>
	</div>

	<div class="controls">
		<i class="material-icons" (click)="leaderboardClosed=true">close</i>
		<i class="material-icons" (click)="leaderboardReversed=!leaderboardReversed">sync_alt</i>
		<!--i class="material-icons" (mousedown)="fastForwarding=true" (mouseup)="fastForwarding=false">fast_forward</i-->
	</div>

</div>



















<div class="logo">
	<div>{{ core.top_domain.split('.')[0] | uppercase }}</div>
	<img [src]="core.logoPath" class="site-logo" [ngClass]="core.top_domain.split('.')[0]"/>
</div>
