import { Component, HostBinding, OnInit, OnDestroy }      from '@angular/core';
import { DomSanitizer, SafeUrl }  from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from '../core.service';


@Component({
	selector: 'app-scoreboards',
	templateUrl: './scoreboards.component.html',
	styleUrls: ['./scoreboards.component.scss']
})
export class ScoreboardsComponent implements OnInit {

	get_rank_name(u) { return {"title":"test", "icon":"i"} }

	constructor( public core: CoreService, private sanitizer:DomSanitizer, private http: HttpClient, private route: ActivatedRoute ){}

	teams = {}
	state = 'p'; mode = 't';
	selectedUserInfo    = null;
	selectedUserLoading = false;
	Math = Math;
	ngOnInit(): void {
		this.core['stats_component'] = this;
		for( let t of this.core.teams )
			this.teams[t['id']] = t;

		let uid = this.route.snapshot.paramMap.get('uid');

		if( uid ){
			this.mode = 'h';
			this.getUserInfo( uid )
		}

		this.core.onGamesChange.subscribe(()=>{
			for( let t of this.core.teams )
				this.teams[t['id']] = t;
		})
	}

	getUserInfo( uid ){
		this.selectedUserLoading = true;
		//this.selectedUserInfo    = null;

		this.http.get(`https://api.${this.core.top_domain}/api/profile/get_user_info/${uid}`, { withCredentials: true }).subscribe( r => {
			this.digestUserInfo(r)
			this.selectedUserInfo    = r;
			this.selectedUserLoading = false;
		}, e => this.selectedUserLoading = false );
	}


	targetOnly: boolean = false;
	digestUserInfo( r ){
		let ranks: number[][] = Object.entries(r['user']['prediction_rank_history']).map( a => [Math.round(+a[0]), a[1]] ).sort((a,b)=> +a[0] - +b[0]) as number[][];

		let max = r['user']['prediction_rank'];
		for( let i = 0; i < ranks.length - 1; i++ ){
			ranks[i].push( ranks[i+1][0] - ranks[i][0] )
			max = Math.max(max, ranks[i][1]);
		}
		ranks.pop()
		r['user']['prediction_rank_history_max'] = max;
		r['user']['prediction_rank_history_dist'] = ranks;

		let ps = r['predictions'], days = [];

		for( let i = 0; i < ps.length; i++ ){
			if( i == 0 || this.core.convertDate( ps[i-1]['datetime'] ).toJSON().slice(0,10) != this.core.convertDate( ps[i]['datetime'] ).toJSON().slice(0,10) )
				days.push([]);
			days[days.length - 1].push(ps[i])
		}
		r['predictions_days'] = days;

		let bs = r['bets']; days = [];
		for( let i = 0; i < bs.length; i++ ){
			if( i == 0 || this.core.convertDate( bs[i-1]['datetime'] ).toJSON().slice(0,10) != this.core.convertDate( bs[i]['datetime'] ).toJSON().slice(0,10) )
				days.push([]);
			days[days.length - 1].push(bs[i])

			bs[i]['home_team'] = this.teams[ bs[i]['game']['home_team_id'] ]
			bs[i]['away_team'] = this.teams[ bs[i]['game']['away_team_id'] ]
		}
		r['bets_days'] = days;


		//Prediction - Per Team
		let team_stats = {}, team_max = 0;
		for( let p of ps ){
			p['home_team'] = this.teams[ p['game']['home_team_id'] ]
			p['away_team'] = this.teams[ p['game']['away_team_id'] ]

			p['bounty'] = p.signed_amount < 0 ? p.game.away_bounty : p.game.home_bounty;
			if( p.status == "W" )
				p['score'] = p['bounty'] * p.game.scoremul * Math.abs(p.signed_amount);
			else if( p.status == "L" )
				p['score'] = (2 - p['bounty']) * p.game.scoremul * Math.abs(p.signed_amount);

			if( p.status == "U" ) continue

			for(
				let tid of (
					this.targetOnly ? [p.on == "A" ? p.game.away_team_id : p.game.home_team_id] : [p.game.away_team_id, p.game.home_team_id]
				)
			){
				if( !team_stats[ tid ] ) team_stats[ tid ] = { "pos": 0, "neg": 0 }

				if( p.status == "W" )      team_stats[ tid ]['pos'] += p['score'];
				else if( p.status == "L" ) team_stats[ tid ]['neg'] += p['score'];

				team_max = Math.max( team_max, team_stats[ tid ]['pos'] - team_stats[ tid ]['neg'] )
			}

		}

		r['team_prediction_max']   = team_max;
		r['team_prediction_stats'] = [];
		for( let s of Object.keys(team_stats).sort((a,b)=>(team_stats[b]['pos']-team_stats[b]['neg'])-(team_stats[a]['pos']-team_stats[a]['neg'])) )
			r['team_prediction_stats'].push({ ...team_stats[s], ...this.teams[s]})


		//Bet - Per Team
		let balance = 1000, min = 1000;
		max = 1000; team_stats = {}; team_max = 0;
		for( let b of r['bets'] ){
			let outcome = ( b.game.winner == "A" ? b.away_outcome : b.home_outcome );
			balance = b.balance = balance + outcome
			max = Math.max( max, balance )
			min = Math.min( min, balance )

			if( b.status == "U" ) continue

			for(
				let tid of (
					this.targetOnly ? [b.on == "A" ? b.game.away_team_id : b.game.home_team_id] : [b.game.away_team_id, b.game.home_team_id]
				)
			){
				if( !team_stats[ tid ] ) team_stats[ tid ] = { "outcome": 0, "win": 0, "loss": 0 }
				team_stats[ tid ]['outcome'] += outcome;

				if( outcome > 0 ) team_stats[ tid ]['win']  += outcome;
				else              team_stats[ tid ]['loss'] -= outcome;

				team_max = Math.max( team_max, team_stats[ tid ]['win'] + team_stats[ tid ]['loss'] )
			}

		}
		r['user']['bet_rank_history_max'] = max;
		r['user']['bet_rank_history_min'] = min * 0.9 - 50;

		r['team_bet_max']   = team_max;
		r['team_bet_stats'] = [];
		for( let s of Object.keys(team_stats).sort((a,b)=>team_stats[b]['outcome']-team_stats[a]['outcome']) )
			r['team_bet_stats'].push({ ...team_stats[s], ...this.teams[s]})
	}

}
