import { Component, HostBinding, ViewChild, ElementRef } from '@angular/core';
import { CoreService } from '../core.service'

var CONF = window['CONF'];
@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
	constructor( public core: CoreService ){
		this.core.headerComponent = this;
	}

	leaderboardClosed: boolean = false;
	leaderboardReversed: boolean = false;
	fastForwarding: boolean = false;


	premiumLogoutConfirming: boolean = false;
	close_premium_logout_panel(event, node): void {
		if( event.target == node ) this.premiumLogoutConfirming = false;
	}

	premiumError: string = null;
	@ViewChild('code1') code_1: ElementRef;
	@ViewChild('code2') code_2: ElementRef;
	@ViewChild('code3') code_3: ElementRef;
	@ViewChild('code4') code_4: ElementRef;
	_premiumCode: string = null;
	get premiumCode() {
		try{ return `${this.code_1.nativeElement.value}${this.code_2.nativeElement.value}${this.code_3.nativeElement.value}${this.code_4.nativeElement.value}`; }
		catch(e){ return this._premiumCode; }
	}
	set premiumCode(v) {
		this._premiumCode = v
		try {
			v = v.replace(/-/g, '');
			this.code_1.nativeElement.value = v.slice(0,   4);
			this.code_2.nativeElement.value = v.slice(4,   8);
			this.code_3.nativeElement.value = v.slice(8,  12);
			this.code_4.nativeElement.value = v.slice(12, 16);
		} catch(e){}
	}
	premiumPaste(e){
		this.premiumError = null;
		let v = e.clipboardData.getData('text').replace(/\s|-/g, '');
		if( /^\d{16}$/.test(v) ){
			this.premiumCode = v;
			this.code_4.nativeElement.focus()
			this.code_4.nativeElement.selectionStart = 4;
			this.premiumGo()
		}
		return false;
	}
	premiumKeyDown(e, prev, next){
		this.premiumError = null;
		if( e.key == "Enter" ){
			this.premiumGo()
			return true;
		}

		if( e.key.length > 1 || e.ctrlKey || /\d/g.test(e.key) ){

			if( next && e.target.value.length == 4 && /\d/g.test(e.key) )
				next.focus();

			if( next && e.target.selectionEnd == 4 && e.keyCode == 39 ){
				next.focus();
				return false;
			}

			if( prev && e.target.value.length == 0 && e.keyCode == 8 )
				prev.focus();

			if( prev && e.target.selectionStart == 0 && e.keyCode == 37 ){
				prev.focus();
				return false;
			}
			return true;
		}
		return false;
	}
	premiumGo( silent = false ){
		var pcode = this.premiumCode;
		if( pcode.length < 16 ){
			if( !silent ) this.premiumError = `<span class="warn-red">Invalid Premium Code, enter all 16 digits.</span>
			<span>Don't have a Code? Purchase one <a class='premium-purchase' href='https://account24network.com/' target='_blank'>from <b>Here</b>!</a></span>`
			return;
		}
		var post_args = { "code" : pcode, "noaddr": true };
		if( this.core.is_premium )
			post_args["device_uuid"] = localStorage['device_uuid'];

		this.core.http.post("https://account24network.com/api/profile/get_premium_code", post_args).subscribe(r=>{
			if(!r){
				if( !silent ) this.premiumError = `<span class="warn-red">Invalid Premium Code! Try again.</span>
				<span>Don't have a Code? Purchase one <a class='premium-purchase' href='https://account24network.com/' target='_blank'>from <b>Here</b>!</a></span>`
				return;
			}
			var current_plink = null;
			for( var plink of r['payment_links'] ){
				if( plink['entitlement']['name'].toLowerCase() == this.core.sport) current_plink = plink; //TODO: Extend with actuall entitlement later
			}
			if( !current_plink ){
				if( !silent ) this.premiumError = `<span class="warn-red">This Code has not been activated for this site!</span>
				<span>To activate it, add credits <a class='premium-purchase' href='https://account24network.com/' target='_blank'>from <b>Here</b>!</a></span>`
				return;
			}
			if( current_plink['seconds_left'] < 0 && r['type']=="G" ){
				if( !silent ) this.premiumError = `<span class="warn-red">This Gift Code has expired at ${current_plink['expiration_date']}!</span>
				<span>Purchase one <a class='premium-purchase' href='https://account24network.com/' target='_blank'>from <b>Here</b>!</a></span>`
				return;
			}
			if( current_plink['seconds_left'] < 0 && current_plink['ever_funded'] ){
				if( !silent ) this.premiumError = `<span class="warn-red">This Premium Code has expired at ${current_plink['expiration_date']}!</span>
				<span>Add credits <a class='premium-purchase' href='https://account24network.com/' target='_blank'>from <b>Here</b>!</a></span>`
				return;
			}
			if( current_plink['seconds_left'] < 0 && !current_plink['ever_funded'] ){
				if( !silent ) this.premiumError = `<span class="warn-orange">No funds recieved! Maybe try again in a minute?</span>
				<span>Haven't deposited any? <a class='premium-purchase' href='https://account24network.com/' target='_blank'>deposit from <b>Here</b>!</a></span>`
				return;
			}
			r['credit'] = current_plink;

			if( this.core.is_premium ){
				this.core.premium_info = r;
				//this.core.bungeePost({"custom_roles":["Premium"]});


			} else {
				localStorage['premium_code'] = r['code'];
				var f = document.createElement('form');
				f.action=`https://${this.core.sport}24network.com/`;
				f.method='POST';
				f.target='_self';

				var i=document.createElement('input');
				i.type='hidden';
				i.name='premium_code';
				i.value=pcode;
				f.appendChild(i);

				document.body.appendChild(f);
				f.submit();
			}
		})
	}
}
