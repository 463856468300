import { Component } from '@angular/core';
import { CoreService } from '../core.service'

@Component({
	selector: 'app-predictions',
	templateUrl: './predictions.component.html',
	styleUrls: ['./predictions.component.scss']
})
export class PredictionsComponent {


	//Ranking
	rankingRulesShown: boolean = false;
	close_rankinfo_panel(event, node): void {
		if( event.target == node ) this.rankingRulesShown = false;
	}

	rank: any;
	generateRankOfUser(user){
		let v = user.prediction_rank_value
		let r = user.prediction_rank
		let c = user.total_right_predictions + user.total_wrong_predictions
		let title, icon
		let over = false;

		if( c < 10 ){
			icon  = 'nature_people'
			title = 'Commoner'
			over  = true;
		} else if ( c < 25 ) {
			icon  = 'child_care'
			title = 'Newbie'
			over  = true;
		} else if( v > 40 ){
			icon  = "cake"
			title = "PieceOfCake"
		} else if( v > 5 ){
			icon  = "school"
			title = "Graduate"
		} else if( v > -1 ){
			icon  = "healing"
			title = "Ouch"
		} else {
			icon  = "pool"
			title = "AgainstTheTide"
		}

		if( !over ){
			if( r == 1 ){
				icon  = "visibility"
				title = "Oracle"

			} else if( r <= 10 ){
				title = "Masterful"

				if( r == 2 )
					icon  = "looks_two"
				if( r == 3 )
					icon  = "looks_3"
				if( r >  3 )
					icon  = "money"

			} else if( r <= 20 ){
				icon  = "face"
				title = "Apprentice"
			}
		}

		return {
			"title" :title,
			"icon"  :icon,
			"calibration_left": 100 - user.total_right_predictions - user.total_wrong_predictions,
		}

	}



	//Schedule
	dateBefore: Set<number> = new Set(); //game_ids...
	timeBefore: number = null;
	sameBefore: Set<number> = new Set(); //game_ids

	//Actual Predicting
	amounts: any = {}; //game_id -> number (-100 -> 100)
	beating: Set<number> = new Set(); //game_id
	loading: Set<number> = new Set();

	abs = Math.abs;
	affirmSide( id: number, side: string ){
		let amount = (side == 'A') ? -25 : +25;

		let sign = this.amounts[id] || 0; // new amount with +/- of side

		if( amount * sign < 0 )
			sign = amount;
		else if( Math.abs(sign) == 100 )
			sign = 0;
		else
			sign += amount;

		this.amounts[id] = sign;
	}


	commitPrediction( id: number ): void {
		let signed_amount = this.amounts[id];

		if( !signed_amount ){
			this.beating.add( id )
			setTimeout( () => { this.beating.delete( id ) }, 1600 );
			return;
		}

		if( !this.core.auth ){
			this.core.onAuthRequired.emit();
			return;
		}

		this.loading.add( id );

		this.core.http.post<any>( `https://api.${this.core.top_domain}/api/prediction/predict_game`,
			{ 'game_id': id, 'on' : signed_amount > 0 ? 'H' : 'A', 'amount' : Math.abs(signed_amount) },
			{ withCredentials: true }
		).subscribe( (success) => {
			this.loading.delete( id );

			if( success ) this.core.getMe();
		}, (err)=>{
			this.loading.delete( id );
		});
	}



	autoScrollActive: boolean = true;
	autoScrollReactivationTimeout: any = null;
	onScroll(e){
		this.autoScrollActive = false;

		if( this.autoScrollReactivationTimeout )
			clearTimeout( this.autoScrollReactivationTimeout );

		this.autoScrollReactivationTimeout = setTimeout(()=>{ this.autoScrollActive = true }, 60000);
	}


	constructor( public core: CoreService ){

		this.core.onGamesChange.subscribe(()=>{
			let last_day = null;
			let last_datetime = null;
			this.dateBefore.clear()
			this.sameBefore.clear()

			for( let g of this.core.games ){
				let day = this.core.convertDate(g.start_datetime);
				let date = `${day.getFullYear()}-${day.getMonth()}-${day.getDate()}`;
				if( last_day != date ){
					last_day = date;
					this.dateBefore.add(g.id);
				}

				if( last_datetime == g.start_datetime )
					this.sameBefore.add(g.id);
				last_datetime = g.start_datetime;
			}

			if( this.autoScrollActive )
				setTimeout(() => {
					let node = document.getElementById('clock_element');
					if( node )
						node.parentNode['scrollTo']({ 'left':node['offsetLeft'] - node.parentNode['offsetWidth']/3 , 'behavior': 'smooth'});
						this.autoScrollActive = false;
				}, 500);

		});

		this.core.onClockTick.subscribe(()=>{
			if( !this.core.games ) return;

			this.timeBefore = null;
			for( let g of this.core.games )
				if( new Date(g.start_datetime) >= this.core.currentDatetime )
					{this.timeBefore = g.id; return}
		})

		this.rank = this.generateRankOfUser(this.core.user);

		this.core.onAuthUpdate.subscribe(()=>{
			this.rank = this.generateRankOfUser(this.core.user);
		})


		this.core.predicitonsComponent = this;
	}




}
