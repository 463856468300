<div class="message" *ngIf="message_shown">
	<span  *ngIf="message_text">{{ message_text }}</span>
	<small *ngIf="message_subtext">{{ message_subtext }}</small>
	<button
		*ngIf="health_check_jobs && health_check_jobs.length"
		[class.unskippable]="!health_check_skippable"
		(click)="health_check_skip()"
		class="health-check">

		<i *ngFor="let job of health_check_jobs"
			[class.rotating]="job.icon=='autorenew'"
			[class.unlocked]="job.icon=='lock_open'"
			[class.checking]="job.icon=='flaky'"
			[ngClass]="job.type"
			class="material-icons">{{ job.icon }}</i>
	</button>
</div>
<!--div class="settings" *ngIf="settings_shown">
	<div>
		Change Player Type:
		<button (click)="change_type('shaka')">Shaka</button>
		<button (click)="change_type('clappr')">clappr</button>
		<button (click)="change_type('proxy')">proxy</button>
	</div>
	<div>
		Perform Health Check:
		<button (click)="health_check_init( play_current_url, false )">Run</button>=
	</div>
</div-->
<div id="player_{{ playerId }}" class="pl-wrapper"></div>
