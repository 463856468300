import { Component, OnInit, OnDestroy, Input, EventEmitter } from '@angular/core';
import { CoreService } from '../core.service';
import { StreamComponent } from '../stream/stream.component';


@Component({
	selector: 'app-player',
	templateUrl: './player.component.html',
	styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnInit, OnDestroy {

	//onPlaybackStateChange: EventEmitter<string> = new EventEmitter();
	@Input() streamComponent: StreamComponent;
	@Input() playerId: number;
	get elementId(){ return `player_${this.playerId}` }
	constructor( public core: CoreService ){}
	settings_shown: boolean = false;

	resize_observer: ResizeObserver = null;
	subscriptions: any[] = [];
	ngOnInit(){
		setTimeout(() => { //To not confuse Angular: ExpressionChangedAfterItHasBeenCheckedError
			this.streamComponent.players[this.playerId] = this;
			if( this.streamComponent.selectedPlayerIndex === null )
			this.streamComponent.selectedPlayerIndex = this.playerId

			this.init()
		}, 0);

		this.subscriptions.push(
			this.core.onNewPremiumSig.subscribe( () => this.playFlavoredStream() )
		);

		this.resize_observer = new ResizeObserver( () => this.resize() );
		this.type = this.core.settings.player_type;
	}
	ngOnDestroy(){
		for( let sub of this.subscriptions )
			sub.unsubscribe();

		this.streamComponent.players[this.playerId] = null;
		this.resize_observer.disconnect();
	}




	// **********
	// -- Generic Player Logic
	// **********
	aspect_ratio: number = 9/16;
	type: string = "shaka";
	change_type( type ){
		if( this.type == type ) return;
		let last_url = this.play_current_url;
		this.destroy();
		this.type = type;
		this.init();

		if( last_url )
			this.play( last_url )
	}

	//Start playing a video
	play_delayed_interval = null;
	play_current_url = null;
	get ready(){
		return this[`${this.type}_ready`];
	}
	init(){
		this[`${this.type}_init`]()

		//If it's resizable, start observing the parrent!
		if( this[`${this.type}_resize`] )
			this.resize_observer.observe(
				document.getElementById( this.elementId ).parentElement
			);
		else
			this.resize_observer.disconnect();
	}
	play( url ){
		if( this.play_delayed_interval )
			clearInterval( this.play_delayed_interval );

		this.messageClear();
		if( this.ready ){
			this.play_current_url = url;
			this[`${this.type}_play`]( url )
			return;
		}

		this.message_text    = "Loading Video Player..."
		this.message_subtext = "This shouldn't take long, if it does, check your internet connection!"
		this.play_delayed_interval = setInterval( () => {
			if ( this.ready )
				this.play( url )
		}, 100 );
	}
	stop(){
		if( this.play_delayed_interval )
			clearTimeout( this.play_delayed_interval );
		this.messageClear();
		this.health_check_init( null, false );
		this.play_current_url = null;
		this[`${this.type}_stop`]()
	}
	destroy(){
		try      { this.stop() }
		catch(e) { console.error("Graceful Stop failed:", e); }
		try      { this[`${this.type}_destroy`](); }
		catch(e) { document.getElementById( this.elementId ).innerHTML = ""; }
		this.resize_observer.disconnect();
	}
	resize(){
		if( this[`${this.type}_resize`] ){ //Not Every player needs manual resizing.
			let newWidth  = document.getElementById( this.elementId ).parentElement.offsetWidth;
			let newHeight = 2 * Math.round( newWidth*(this.aspect_ratio)/2 );
			this[`${this.type}_resize`]( newHeight, newWidth );
		}
	}

	//onLoad(){
		//aclibTimeout = null;
		/*if( window['aclib'] && !this.pl.with_ads ){
			console.log("Will run ad in 15 seconds:")
			this.pl.with_ads = true;

			if( this.aclibTimeout !== null )
				clearTimeout( this.aclibTimeout );

			this.aclibTimeout = setTimeout(() => {
				window['aclib'].runPop({ zoneId: '7728366' });
				this.aclibTimeout = null;
			}, 15 * 1000);
		}*/
	//}







	liveDistance: number = null;
	turboActive:  boolean = false; turboToggle(){ this.turboActive = !this.turboActive }
	turboCapable: boolean = false;

	link: any = null; link_id = null;
	feed: any = null; feed_id = null;
	game: any = null; game_id = null;
	flavor: any = null; //Null is default, False is direct!


	assignLink( link_id, feed_id, flavor, game_id = null ){
		this.link_id = link_id;
		this.feed_id = feed_id;
		this.game_id = game_id;

		this.link = this.core.id2links[link_id];
		this.feed = this.core.feeds[feed_id];
		this.game = this.core.id2games[game_id];
		this.flavor = flavor;

		if( !this.core.is_premium && flavor == false && this.link.provider == "NHL" )
			 this.change_type( "proxy" );


		this.stop();

		if( this.link.url === false ){
			this.message_text    = `This stream is not playable!`;
			this.message_subtext = `at least not yet.`;
			return;
		}

		if( flavor === false ) //It's a direct link
			this.play( this.link.url );

		else
			this.playFlavoredStream()
	}

	current_stream_key: string = null;
	playFlavoredStream(){
		if( !this.flavor ) return;
		let flavor = this.flavor;

		var stream_key = `${this.link.id}|${flavor['unique_id']}`;
		if( stream_key == this.current_stream_key )
			return;

		let payload = {
			"source_id": this.link.id,
			"flavor": flavor['unique_id'],
		};

		if( flavor['premium'] )
			if( this.core.premium_sig )
				payload["token"] = this.core.premium_sig;
			else if( this.core.premium_sig === null ){
				this.message_text    = `Verifying Premium Code,`;
				this.message_subtext = `Please wait...`;
				return
			} else if( this.core.premium_sig === false ){
				this.message_text    = `Premium Code Verification Failed!`;
				this.message_subtext = `Click on the error icon on top of the premium code section to retry.`;
				return
			}

		this.message_text = `Loading Stream Flavor '${flavor['name']}'...`
		this.core.http.post(
			`https://api.${this.core.top_domain}/api/generate_stream_info`, payload, { withCredentials: true }
		).subscribe( r => {

			if( r['error'] ){
				this.current_stream_key = null;
				this.message_text    = `Service Currently Unavailable`;
				this.message_subtext = r['message'] || 'Try again later!';
				return;
			}

			this.current_stream_key = stream_key;
			if( !r['wait'] ){
				this.play( r['url'] );
				return;
			}

			this.message_text    = 'Stream is warming up!';
			this.message_subtext = 'Running a health check...';
			this.health_check_init( r['url'], true );

		}, ()=>{

			this.current_stream_key = null;
			this.message_text    = `Failed Loading Stream Flavor '${flavor['name']}'!`;
			this.message_subtext = `Maybe try another flavor.`;

		} )

	}

	//turbo stuff
	/*mediaDuration: number = null;
	setMediaDurationFromRequest( http ){
		let text = http.responseText;

		let d = 0;

		if( !text.match(/\n#EXT-X-ENDLIST\n*$/g) )
			for( let m of text.matchAll(/\n#EXTINF:([^,]+),/g) )
				d += +m[1];

		this.turboCapable = !!d;
		if( !d ) return

		let ct = this.pl.getCurrentTime();
		this.mediaDuration = d;
		if( ct && this.pl.isPlaying() )
			this.liveDistance = d - ct;
		else
			this.liveDistance = null;

	}*/











	// *********
	// -- SHAKA STUFF:
	// **********
	shaka:    any = null; //The library object: If null, library is not pollyfilled
	shaka_ui: any = null; //The UI we have to attach: If null, ui is not drawn.
	shaka_player: any = null;
	shaka_ready: boolean = false;
	shaka_init(){
		if( !window['shaka'] ){
			//Since load script will not load the same thing twice,
			//it can also be used as a delay method!
			this.core.load_scripts(
				"/assets/shaka/shaka-player.ui.js"
			).subscribe(()=>{
				setTimeout(()=>{ this.shaka_init(); }, 10)
			})
			return;
		}

		if( !this.shaka ){
			this.shaka = window['shaka'];

			// Install built-in polyfills to patch browser incompatibilities.
			this.shaka.polyfill.installAll();

			// Check to see if the browser supports the basic APIs Shaka needs.
			if( !this.shaka.Player.isBrowserSupported() ){
				// This browser does not have the minimum set of APIs we need.
				this.message_text    = "Browser Does not support shaka player.";
				this.message_subtext = "Choose another player in the settings!";
				return;
			}
		}

		let wrapper = document.getElementById( this.elementId )
		let video   = wrapper.querySelector("& > video") || document.createElement("video")

		video.setAttribute("autoplay", "true")
		video.setAttribute("crossorigin", "true")
		video.setAttribute("playsinline", "true")
		if( this.core.should_play_muted )
			video.setAttribute("muted", "true")
		wrapper.appendChild( video )

		this.shaka_player = new this.shaka.Player();
		this.shaka_ui     = new this.shaka.ui.Overlay(this.shaka_player, wrapper, video);
		this.shaka_player.addEventListener('error', (event)=>{
			let error = event.detail;
			console.error('Shaka Error code:', error.code);
		});

		this.shaka_player.attach( video ).then(()=>{
			this.shaka_ready = true;
		})
	}
	shaka_play( url ){
		let config = {
			'seekBarColors': {
				base: '#8a8a8a5e',
				buffered: '#ffffff5e',
				played: this.link.status == "L" ? '#ff00005e': '#4f7bff5e',
			},
		}

		if( this.core.is_premium ){

			// Set the castReceiverAppId, Other Potential IDs: 930DEB06", "B3F2CAE7"
			config['castReceiverAppId'] = '07AEE832';

			// Enable casting to native Android Apps (e.g. Android TV Apps)
			config['castAndroidReceiverCompatible'] = true;

		}

		this.shaka_ui.configure( config );
		this.shaka_player.load( url ).catch(err=>{

			if( err['code'] != 7000 ) //Loaded/Unloaded another video mid load!
				console.error("Shaka Load ERR:", err)

		});

	}
	shaka_stop(){
		if( this.shaka_player )
			this.shaka_player.unload();
	}
	shaka_destroy(){
		this.shaka_ready = false;
		this.shaka_player.destroy();
		this.shaka_ui.destroy();
		let wrapper = document.getElementById( this.elementId );
		wrapper.innerHTML = "";

		for( let attr of [...wrapper.attributes] )
			if( attr.name.includes("shaka") )
				wrapper.attributes.removeNamedItem( attr.name );

		for( let cls of [...wrapper.classList] )
			if( cls.includes("shaka") )
				wrapper.classList.remove( cls );

		wrapper.style.cursor="unset";
	}



	// *********
	// -- PROXY STUFF:
	// **********
	proxy: any = null;
	proxy_origin: string = "https://availablestream.com";
	proxy_listening: boolean = false;
	proxy_ready: boolean = false;
	proxy_resize_observer: any = null;

	proxy_init(){
		this.proxy = document.createElement("iframe");
		this.proxy.setAttribute('allowFullScreen', '')
		this.proxy.classList.add("proxy-frame")
		this.proxy.src = `${this.proxy_origin}/player.html?origin=${location.origin}&sport=${this.core.sport.toUpperCase()}`;

		let wrapper = document.getElementById( this.elementId );
		wrapper.appendChild( this.proxy );

		if( !this.proxy_listening ){
			this.proxy_listening = true;
			window.addEventListener("message", (event) => {
				if( event.origin !== this.proxy_origin ) return;

				if( event['data']['ready'] )
					this.proxy_ready = true;

			}, false);
		}
	}
	proxy_play( url ){
		this.proxy.contentWindow.postMessage({"play":url}, this.proxy_origin)
	}
	proxy_stop(){
		this.proxy.contentWindow.postMessage({"clappr_stop":true}, this.proxy_origin)
	}
	proxy_destroy(){
		if( this.proxy ) this.proxy.remove();
		this.proxy = null;
		this.proxy_ready = false;
	}
	proxy_resize( h, w ){
		if( this.proxy ){
			this.proxy.width  = w + "px";
			this.proxy.height = h + "px";
		}
	}



	// *********
	// -- CLAPPR STUFF:
	// **********
	clappr: any = null;
	clappr_ready: boolean = false;
	clappr_player: any = false;

	clappr_init(){
		if( !window['Clappr'] ){
			//Since load script will not load the same thing twice,
			//it can also be used as a delay method!
			this.core.load_scripts(
				"https://cdn.jsdelivr.net/npm/clappr@latest/dist/clappr.min.js"
			).subscribe(()=>{
				setTimeout(()=>{this.clappr_init();}, 10);
			})
			return;
		}

		if( !this.clappr ){
			this.clappr = window['Clappr'];
			let scripts = ["https://cdn.jsdelivr.net/gh/clappr/clappr-level-selector-plugin@latest/dist/level-selector.min.js"];

			if( this.core.is_premium )
				scripts.push(...[
					"https://cdn.jsdelivr.net/npm/clappr-playback-rate-plugin@latest/lib/clappr-playback-rate-plugin.min.js",
					"https://cdn.jsdelivr.net/npm/clappr-chromecast-plugin@latest/dist/clappr-chromecast-plugin.min.js"
				]);

			this.core.load_scripts( ...scripts ).subscribe(()=>{
				this.clappr_ready = true;
			});
			return;
		}

		this.clappr_ready = true;
	}
	clappr_play( url ){
		let plugins = [];

		if( window['LevelSelector'] )
			plugins.push( window['LevelSelector'] )

		if( window['PlaybackRatePlugin'] )
			plugins.push( window['PlaybackRatePlugin'] )

		if( window['ChromecastPlugin'] )
			plugins.push( window['ChromecastPlugin'] )


		this.clappr_player = new this.clappr.Player({

			parentId: "#"+this.elementId, source: url,
			mimeType: url.endsWith('.mp4') ? "video/mp4" : "application/x-mpegurl",
			mute: this.core.should_play_muted,

			flushLiveURLCache: false,
			disableVideoTagContextMenu: true,
			playback: {
				autoPlay : true,
				preload: "metadata",
				controls: false,
				playInline: true, // allows inline playback when running on iOS UIWebview
				crossOrigin: true,
			},
			plugins: plugins,
			events: {
				onPlay: (e)=>{
					//this.onPlaybackStateChange.emit("playing");
				},
				onReady: (e)=>{
					/*setTimeout(() => {
						this.pl.core.getCurrentPlayback()._hls.on('hlsLevelLoaded', (e, inf)=>{
							this.setMediaDurationFromRequest( inf.networkDetails );
						});
					}, 0);*/
				},
			},

			chromecast: {
				appId: '9DFB77C0',
				contentType: url.endsWith('.mp4') ? 'video/mp4' : "application/x-mpegurl",
				media: { title: window['CONF']['title'] },
				poster: `https://${location.host}${window['CONF']['logo']}`,
			},

		});

		this.clappr_player.play();
		this.resize();
	}
	clappr_stop(){
		if( this.clappr_player )
			this.clappr_player.stop();
	}
	clappr_destroy(){
		if( this.clappr_player )
			this.clappr_player.destroy();

		this.clappr_ready = false;
		this.clappr_player = null;
	}
	clappr_resize( h, w ){
		if( this.clappr_player )
			this.clappr_player.resize({"height":h, "width":w});
	}

	// *********
	// -- Health Check and Message:
	// **********
	message_text: string = null;
	message_subtext: string = null;
	messageClear() {
		this.message_text = null;
		this.message_subtext = null;
	}
	get message_shown(){
		return (
			this.message_text ||
			this.message_subtext ||
			this.health_check_jobs
		)
	}

	health_check_skippable: boolean = false;
	health_check_attempts: number = 3;
	health_check_autoplay: boolean = false;
	health_check_jobs: any[] = null;
	health_check_init( url, autoplay ){
		this.health_check_skippable = false;
		this.health_check_attempts  = autoplay ? 30 : 3;
		this.health_check_autoplay  = autoplay;

		//Clear Potential Old jobs
		for( let job of this.health_check_jobs || [] ){
			if( job.timeout )
				clearTimeout( job.timeout );

			if( job.observer )
				job.observer.unsubscribe();
		}

		this.health_check_jobs = null;
		if( !url ) return;

		this.health_check_jobs = [{
			"type": "master",
			"url": url,
			"state": "Queued",
			"attempted": 0,
			"icon": "autorenew",
		}];
		this.health_check_step();
	}
	health_check_step(){
		let state_set = new Set();
		for( let job of this.health_check_jobs )
			if( job.state == "Queued" ){
				job.state = "Running"

				let ms = 0;
				if( job.attempted ) //Delay with Jitter
					ms = (job.attempted * 500) + (Math.random() * 4000)

				job.attempted += 1;
				job.timeout = setTimeout(() => {
					try {
						this[`health_check_${job.type}`]( job );
					} catch(e) {
						job.state = "Error";
						console.log( "Job Error:", e );
					}
				}, ms );
				return;
			} else state_set.add( job.state )

		if( state_set.has("Running") )
			return;

		if( !state_set.has("Error") ){
			if( !this.health_check_autoplay ){
				this.health_check_jobs[0].icon = "play_arrow";
				this.message_text    = `All OK!`;
				this.message_subtext = `Press on any of the symbols below to watch the stream.`;
				return
			}

			let url = this.health_check_jobs[0].url;
			this.health_check_init( null, false ); //Clear everything
			this.play( url );
			return;
		}

		if( !this.health_check_autoplay ){
			this.health_check_jobs[0].icon = "play_arrow";
			this.message_text    = `Issues detected during health check!`;
			this.message_subtext = `Press on any of the symbols below to try to watch the stream anyway!`;
			this.health_check_skippable = true;
			return
		}

	}
	health_check_skip(){
		if( !this.health_check_skippable ) return;

		let url = this.health_check_jobs[0].url;
		this.health_check_init( null, false ); //Clear everything
		this.play( url );
	}

	health_check_master( job ){
		job.observer = this.core.http.get( job.url, {responseType: 'text'} ).subscribe(
			(r) => {

				let is_master = false;
				for( const media of r.matchAll(/#EXT-X-STREAM-INF.*\n(.*)\n/g) ){
					is_master = true;
					let url = (new URL( media[1], job.url )).toString();
					this.health_check_jobs.push({
						"type":"media",
						"url":url,
						"state":"Queued",
						"attempted":0,
						"icon": "flaky",
					})
					this.health_check_step();
				}

				job.state = "Done";
				if( is_master ){
					this.message_text = `Checking Qualities...`;
					this.message_subtext = `Press the button below to skip this health check.`;
					this.health_check_skippable = true;
					job.icon = "skip_next";
				} else
					job.icon = "check_circle";

			}, (e) => {
				if( job.attempted < this.health_check_attempts ){
					job.state = "Queued";
					this.health_check_step();
					return
				}

				job.state = "Error"
				job.icon  = "sync_problem"
				console.log( e )

				this.message_text    = "Something went wrong!";
				this.message_subtext = "Failed connecting to stream!";
			}
		)
	}
	health_check_media( job ){
		job.observer = this.core.http.get( job.url, {responseType: 'text'} ).subscribe(
			(r) => {

				let current_keys = new Set();
				for( let j of this.health_check_jobs )
					if( j.type == "key" )
						current_keys.add( j.url );

				let is_encrypted = false;
				for( const match of r.matchAll(/#EXT-X-KEY:METHOD=AES-128,URI="([^"]*)",.*\n/g) ){
					let url = (new URL( match[1], job.url )).toString();
					if( !current_keys.has(url) ){
						current_keys.add( url );

						is_encrypted = true;
						console.log("New Key:", url )
						this.health_check_jobs.push({
							"type":"key",
							"url":url,
							"state":"Queued",
							"attempted":0,
							"icon": "lock",
						})
						this.health_check_step();

					}
				}

				job.icon  = "check_circle";
				job.state = "Done";

				if( !is_encrypted )
					this.health_check_step();

			}, (e) => {
				if( job.attempted < this.health_check_attempts ){
					job.state = "Queued";
					this.health_check_step();
					return
				}

				job.icon  = "error";
				job.state = "Error";
				this.health_check_step();
			}
		)

	}
	health_check_key( job ){
		job.observer = this.core.http.get( job.url, {responseType: 'text'} ).subscribe(
			(r) => {
				job.icon  = "lock_open";
				job.state = "Done";
				this.health_check_step();

			}, (e) => {
				if( job.attempted < this.health_check_attempts ){
					job.state = "Queued";
					this.health_check_step();
					return
				}

				job.icon  = "error";
				job.state = "Error";
				this.health_check_step();
			}
		)
	}


}
