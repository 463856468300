import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChatComponent } from './chat/chat.component';
import { DevlogComponent } from './devlog/devlog.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HighlightsComponent } from './highlights/highlights.component';
import { PlayerComponent } from './player/player.component';
import { PredictionsComponent } from './predictions/predictions.component';
import { ScoreboardsComponent } from './scoreboards/scoreboards.component';
import { SettingsComponent } from './settings/settings.component';
import { SimulatorComponent } from './simulator/simulator.component';
import { StreamComponent } from './stream/stream.component';
import { TimemachineComponent } from './timemachine/timemachine.component';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MoneyPipe, FileSizePipe } from './app.pipe';


@NgModule({
	declarations: [
		MoneyPipe, FileSizePipe,


		AppComponent,
		ChatComponent,
		DevlogComponent,
		FooterComponent,
		HeaderComponent,
		HighlightsComponent,
		PlayerComponent,
		PredictionsComponent,
		ScoreboardsComponent,
		SettingsComponent,
		SimulatorComponent,
		StreamComponent,
		TimemachineComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule { }
