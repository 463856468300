<header>
	<p>
		This is where you can see everything about betting/predictions ranking/history of yourself, and others.
	</p>
	<div class="button-wrapper">
		<button (click)="mode='t'; selectedUserInfo = null" [class.selected]="mode=='t'">Top Users</button>
		<button (click)="mode='h'; getUserInfo( core.user.uid )" [class.selected]="mode=='h'">My Stats</button>
	</div>
</header>


<ng-container *ngIf="mode=='h'">

	<div class="state-toggle">
		<button (click)="state='p'" [class.selected]="state=='p'">
			Show Prediction Stats
		</button>
		<button (click)="state='b'" [class.selected]="state=='b'">
			Show Simulator Stats
		</button>
	</div>


	<div class="user-info" *ngIf="selectedUserInfo">
		<div class="user" *ngIf="selectedUserInfo.user as user">
			<div class="basic">
				<img [src]="user.avatar"/>
				<span class="name" [ngClass]="user.role">
					{{ user.username }}
				</span>
				<i *ngIf="user.badge" class="badge material-icons">{{ user.badge }}</i>
			</div>

			<div class="stats predictions" *ngIf="state=='p'">

				<div class="simple-stats">
					<small *ngIf="user.total_pending_predictions">{{ user.total_pending_predictions }} pending prediction{{ user.total_pending_predictions == 1 ? '' : 's' }}</small>
					<span class="title">Prediction Stats</span>
					<small *ngIf="user.total_nulled_predictions">{{ user.total_nulled_predictions }} cancelled prediction{{ user.total_nulled_predictions == 1 ? '' : 's' }}</small>
				</div>

				<div class="stats-history">
					<header>Rank History</header>
					<span *ngFor="let r of user.prediction_rank_history_dist" [style.flex-grow]="r[2]" [style.height.%]="100 - (100 * r[1] / user.prediction_rank_history_max)"><small>{{ r[1] }}</small></span>
					<span class="current" [style.height.%]="100 - (100 * user.prediction_rank / user.prediction_rank_history_max)"><small>{{ user.prediction_rank }}</small></span>
				</div>


				<div class="percentage-bar">
					<span class="right" [style.flex-grow]="user.total_right_predictions"></span>
					<span class="empty" [style.flex-grow]="user.total_wrong_predictions"></span>
					<span class="info">
						<small>{{ user.total_right_predictions | number:'3.0-0' }} Right</small>
						<small class="percentage">{{ user.prediction_success_percentage | number:'2.2-2' }}%</small>
						<small>{{ user.total_wrong_predictions | number:'3.0-0' }} Wrong</small>
					</span>
				</div>

			</div>
			<div class="stats bets" *ngIf="state=='b'">

				<div class="simple-stats">
					<small *ngIf="+user.total_unsettled">${{ user.total_unsettled }} Unsettled</small>
					<span class="title">Betting Stats</span>
				</div>

				<div class="stats-history">
					<header>Balance History</header>
					<span *ngFor="let b of selectedUserInfo.bets" [class.loss]="b.balance < 1000" [style.height.%]="100 * (b.balance-user.bet_rank_history_min) / (user.bet_rank_history_max-user.bet_rank_history_min)"><small>${{ b.balance | number:'2.2-2' }}</small></span>
					<span class="current" [style.height.%]="100 * (user.balance-user.bet_rank_history_min) / (user.bet_rank_history_max-user.bet_rank_history_min)"><small>${{ user.balance | number:'2.2-2' }}</small></span>
				</div>

				<div class="percentage-bar">
					<span class="right" [style.flex-grow]="user.total_winnings"></span>
					<span class="wrong" [style.flex-grow]="user.total_loss"></span>
					<span class="info">
						<small>${{ user.total_winnings | number:'1.0-2' }} Won</small>
						<small class="percentage">Balance: ${{ user.balance | number:'1.0-2' }}</small>
						<small>${{ user.total_loss | number:'1.0-2' }} Lost</small>
					</span>
				</div>

			</div>

		</div>

		<div class="team-stats bet" *ngIf="state=='b'">
			<header>Win/Loss Per team</header>
			<div class="teams" *ngFor="let t of selectedUserInfo.team_bet_stats">
				<i class="background negative" [style.height.%]="100 * t.loss / selectedUserInfo.team_bet_max"></i>
				<i class="background" [style.height.%]="100 * t.win / selectedUserInfo.team_bet_max"></i>
				<img src="/sport-assets/teams/{{ t.abr }}.svg" alt="{{ t.abr }}">
				<span class="name">{{ t.abr }}</span>
				<span class="outcome" [class.negative]="t.outcome < 0">{{ t.outcome >= 0 ? '+' : '-' }}${{ Math.abs(t.outcome) | number:'1.0-2' }}</span>
			</div>
			<footer *ngIf="!targetOnly">
				The numbers you see above, are calculated for each team, regardless of that team being the team that was bet on, or against.<br/>
				You can also choose to only calculate a team's value, on the games that are only bet on, and not against.<br/>
				<button (click)="targetOnly=true; digestUserInfo( selectedUserInfo )">Calculate targeted teams only?</button>
			</footer>
			<footer *ngIf="targetOnly">
				The numbers you see above, are calculated for only the team that was bet on.<br/>
				You can also choose to calculate a team's value, on the games that are both bet on, and against.<br/>
				<button (click)="targetOnly=false; digestUserInfo( selectedUserInfo )">Calculate both teams?</button>
			</footer>
		</div>


		<div class="team-stats prediction" *ngIf="state=='p'">
			<header>Ranking Scores Per Team</header>
			<div class="teams" *ngFor="let t of selectedUserInfo.team_prediction_stats">
				<i class="background negative" [style.height.%]="100 * t.neg / selectedUserInfo.team_prediction_max"></i>
				<i class="background" [style.height.%]="100 * t.pos / selectedUserInfo.team_prediction_max"></i>
				<img src="/sport-assets/teams/{{ t.abr }}.svg" alt="{{ t.abr }}">
				<span class="name">{{ t.abr }}</span>
				<span class="outcome" [class.negative]="t.neg > t.pos">{{ t.neg <= t.pos ? '+' : '-' }}{{ Math.abs(t.pos - t.neg) | number:'1.0-2' }}</span>
			</div>
			<footer *ngIf="!targetOnly">
				The numbers you see above, are calculated for each team, regardless of that team being the team that was predicted to win or lose.<br/>
				You can also choose to only calculate a team's score, on the games that are only predicted to win.<br/>
				<button (click)="targetOnly=true; digestUserInfo( selectedUserInfo )">Calculate targeted teams only?</button>
			</footer>
			<footer *ngIf="targetOnly">
				The numbers you see above, are calculated for only the team that was predicted to win.<br/>
				You can also choose to calculate a team's score, on the games that are on both sides of a prediction.<br/>
				<button (click)="targetOnly=false; digestUserInfo( selectedUserInfo )">Calculate both teams?</button>
			</footer>
		</div>
	</div>


	<div class="history predictions" *ngIf="selectedUserInfo && state=='p'">
		<header>Prediction History</header>
		<ng-container  *ngFor="let d of selectedUserInfo.predictions_days">
			<div class="date-seperator">{{ d[0].datetime | date:'MMMM d, y':core.tz }}</div>
			<div class="day">
				<div class="prediction" [class.won]="p.status=='W'" [class.lost]="p.status=='L'"
					*ngFor="let p of d">
					<header>
						<small class="score win"  *ngIf="p.game.winner=='A'">+{{ p.game.away_bounty * p.game.scoremul * Math.abs(p.signed_amount) | number:'1.2-2' }}</small>
						<small class="score loss" *ngIf="p.game.winner=='H'">-{{ (2-p.game.away_bounty) * p.game.scoremul * Math.abs(p.signed_amount) | number:'1.2-2' }}</small>
						<span>{{ p.datetime | date:'HH:mm':core.tz }} (ET)</span>
						<small class="score win"  *ngIf="p.game.winner=='H'">+{{ p.game.home_bounty * p.game.scoremul * Math.abs(p.signed_amount) | number:'1.2-2' }}</small>
						<small class="score loss" *ngIf="p.game.winner=='A'">-{{ (2-p.game.home_bounty) * p.game.scoremul * Math.abs(p.signed_amount) | number:'1.2-2' }}</small>
					</header>
					<div class="teams">
						<div class="team away">
							<img src="/sport-assets/teams/{{ p.away_team.abr }}.svg" alt="{{ p.away_team.abr }}">
							<span>{{ p.away_team.abr }}</span>
							<i class="material-icons winner" *ngIf="p.game.winner=='A'">emoji_events</i>
							<small class="points" *ngIf="p.signed_amount < 0">{{ Math.abs(p.signed_amount) }}/4 Points</small>
						</div>
						<div class="team home">
							<img src="/sport-assets/teams/{{ p.home_team.abr }}.svg" alt="{{ p.home_team.abr }}">
							<span>{{ p.home_team.abr }}</span>
							<i class="material-icons winner" *ngIf="p.game.winner=='H'">emoji_events</i>
							<small class="points" *ngIf="p.signed_amount > 0">{{ Math.abs(p.signed_amount) }}/4 Points</small>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
	</div>

	<div class="history bet" *ngIf="selectedUserInfo && state=='b'">
		<header>Simulator History</header>
		<ng-container  *ngFor="let d of selectedUserInfo.bets_days">
			<div class="date-seperator">{{ d[0].datetime | date:'MMMM d, y':core.tz }}</div>
			<div class="day">
				<div class="bet" [class.won]="b.status=='W'" [class.lost]="b.status=='L'"
					*ngFor="let b of d">
					<header>
						<small class="score">{{ b.away_odd }}</small>
						<span>{{ b.datetime | date:'HH:mm':core.tz }} (ET)</span>
						<small class="score">{{ b.home_odd }}</small>
					</header>
					<div class="teams">
						<div class="team away">
							<img src="/sport-assets/teams/{{ b.away_team.abr }}.svg" alt="{{ b.away_team.abr }}">
							<span>{{ b.away_team.abr }}</span>
							<i class="material-icons winner" *ngIf="b.game.winner=='A'">emoji_events</i>
							<small class="points" *ngIf="b.on == 'A'">${{ b.amount | number:'1.0-2' }}</small>
						</div>
						<div class="team home">
							<img src="/sport-assets/teams/{{ b.home_team.abr }}.svg" alt="{{ b.home_team.abr }}">
							<span>{{ b.home_team.abr }}</span>
							<i class="material-icons winner" *ngIf="b.game.winner=='H'">emoji_events</i>
							<small class="points" *ngIf="b.on == 'H'">${{ b.amount | number:'1.0-2' }}</small>
						</div>
					</div>
					<footer>
						<small class="score" [class.win]="b.game.winner=='A'" [class.loss]="b.game.winner!='A'">{{ b.game.winner=='A' ? '+' : '-' }}${{ Math.abs(b.away_outcome) | number:'1.2-2' }}</small>
						<wbr/>
						<small class="score" [class.win]="b.game.winner=='H'" [class.loss]="b.game.winner!='H'">{{ b.game.winner=='H' ? '+' : '-' }}${{ Math.abs(b.home_outcome) | number:'1.2-2' }}</small>
					</footer>
				</div>
			</div>
		</ng-container>
	</div>


	<div class="user-info skelet" *ngIf="!selectedUserInfo">
		<div class="user">
			<div class="basic">
				<img src="/assets/placeholder_avatar_dark.png"/>
				<span class="name"><wbr></span>
				<i class="badge material-icons">hourglass_bottom</i>
			</div>
			<div class="stats">
				<div class="simple-stats"><span class="title">Loading Stats...</span></div>
				<div class="stats-history"></div>
				<div class="percentage-bar">
					<span class="empty" [style.flex-grow]="1"></span>
					<span class="empty" [style.flex-grow]="1"></span>
					<span class="info">
						<small><wbr></small>
						<small class="percentage"><wbr></small>
						<small><wbr></small>
					</span>
				</div>
			</div>
		</div>
		<div class="team-stats" style="margin-bottom: 80vh;">
			<header>Loading Per Team Stats</header>
			<div class="teams" *ngFor="let t of core.teams" style="height: 100px;filter: grayscale(1);">
				<img src="/sport-assets/teams/{{ t.abr }}.svg" alt="{{ t.abr }}">
				<span class="name">{{ t.abr }}</span>
				<span class="outcome"><wbr></span>
			</div>
		</div>
	</div>

</ng-container>

<ng-container *ngIf="mode=='t'">

	<div class="state-toggle">
		<button (click)="state='p'" [class.selected]="state=='p'">
			Top {{ core.topPUsers.length }} Predictors
		</button>
		<button (click)="state='b'" [class.selected]="state=='b'">
			Top {{ core.topSUsers.length }} Richest
		</button>
	</div>

	<div class="top-users predictions" *ngIf="state=='p'">
		<div class="user" *ngFor="let u of core.topPUsers" [ngClass]="get_rank_name(u).title" [class.me]="core.auth && u.uid==core.user.uid">
			<button class="show" (click)="getUserInfo(u.uid);mode = 'h'">
				<img src="{{ u.avatar || '/assets/placeholder_avatar_dark.png' }}"/>
				<span>Show</span>
			</button>
			<div class="info">
				<span class="stats">
					<span class="name">{{ u.username }}</span>
					<span class="right">{{ u.last_100_wins }}%</span> In the last
					<span class="total">{{ Math.min(u.total_wrong_predictions + u.total_right_predictions, 100) }}</span>,
					<span class="right">{{ u.total_right_predictions }}</span> Of total
					<span class="total">{{ u.total_wrong_predictions + u.total_right_predictions }}</span> Predictions
					<small class="rate">{{ 100 * u.total_right_predictions / (u.total_wrong_predictions + u.total_right_predictions) | number:'2.2-2' }}%</small>
				</span>
				<span class="ranks">
					Rank:
					<span class="rank">{{ u.prediction_rank }}</span>
					<span class="title"> {{ get_rank_name(u).title }}</span>
					<small class="score">
						 ( score: {{ u.prediction_rank_value  | number:'1.2-2' }} )
					</small>
				</span>
			</div>
			<i class="material-icons">{{ get_rank_name(u).icon }}</i>
		</div>

		<ng-container *ngIf="core.auth && core.user as u">
			<div class="seperator"><i class="material-icons">more_vert</i></div>
			<div class="user me" [ngClass]="get_rank_name(u).title">
				<button class="show" (click)="getUserInfo(u.uid);mode = 'h'">
					<img src="{{ u.avatar || '/assets/placeholder_avatar_dark.png' }}"/>
					<span>Show</span>
				</button>
				<div class="info">
					<span class="stats">
						<span class="name">{{ u.username }}</span>
						<span class="right"> {{ u.total_right_predictions }}</span> Of
						<span class="total">{{ u.total_wrong_predictions + u.total_right_predictions }}</span> Predictions
						<small class="rate">{{ 100 * u.total_right_predictions / (u.total_wrong_predictions + u.total_right_predictions) | number:'2.2-2' }}% Success Rate</small>
					</span>
					<span class="ranks">
						Rank:
						<span class="rank">{{ u.prediction_rank }}</span>
						<span class="title"> {{ get_rank_name(u).title }}</span>
						<small class="score"> (score: {{ u.prediction_rank_value  | number:'1.2-2' }})</small>
					</span>
				</div>
				<i class="material-icons">{{ get_rank_name(u).icon }}</i>
			</div>
		</ng-container>

		<div class="note">
			<span>Total Users: <span>{{ core.totalUsers }}</span></span>
			<span>Participants: <span>{{ core.predictors }}</span></span>
			<span>Active Users: <span class="lime">{{ core.recentUsers }}</span></span>
			<span>Qualified for a Rank: <span class="lime">{{ core.activeUsers }}</span></span>
		</div>

		<footer>
			<p class="title">Ranking Algorithm Details: <small>How the score value is calculated</small></p>
			<p class="text">
				Our current ranking algorithm is a <b class="bounty">Bounty</b>/<b class="penalty">Penalty</b> system.<br/>
				When a game concludes, it'll recieve a <b class="bounty">bounty</b> value
				- ranging form 0 to 100 depending on the game's difficulty and final score -
				Those who had predicted the outcome of the game correctly, for each prediction point,
				will have the <b class="bounty">bounty added</b> to their score,
				and those who have wrongly predicted the outcome, for each prediction point,
				will have it's inverse <b class="penalty">(2 - bounty) subtracted</b> from their score.<br/><br/>

				Users with low activity - having overall less than 100 predictions.
				Or those who have been inactive for 7 days will not be competing in the top section,
				but they will be still visible and ordered in their own section(s).
			</p>
			<code class="d-block">
				Goal Difference of a game:<br/>
				&nbsp;&nbsp;<i>&Delta;S</i><br/><br/>

				Nomalized version of the game's last odd before it started:<br/>
				&nbsp;&nbsp;<i>Ow</i> -&gt; Winner's odd<br/>
				&nbsp;&nbsp;<i>Ol</i> -&gt; Loser's odd<br/>
				&nbsp;&nbsp;Normalized -&gt; <i>Ow</i> + <i>Ol</i> = 4<br/><br/>

				<b class="bounty">Bounty</b> of the game:<br/>
				&nbsp;&nbsp; <span><i>B</i> = <i>Ow</i> &times; <sup>(1 - e<sup>-<i>&Delta;S</i></sup>)</sup>/<sub>(1 + e<sup>-<i>&Delta;S</i></sup>)</sub><br/></span><br/>

				<b class="penalty">Penalty</b> of the game:<br/>
				&nbsp;&nbsp; <span><i>P</i> = 2 - <i>B</i></span>
			</code>
			<p class="note">
				A note from Queue A:<br/>

				Although the bounty formula may seem rather complex, It can be summerized easily:<br/>
				The maximum amount of bounty a game can have, is first decided by the goal differene,
				the higher the goal differene, the better the maximum bounty.<br/>
				Example: 1 Diff = 76, 2 Diff = 90, 3 Diff = 96, and so on...<br/>

				Now the actual amount of the bounty is based on just how much likley it was for the team to win before the game was started,
				The more likely it was, the lower the bounty (and higher the penalty).<br/><br/>

				Thats it.
			</p>
		</footer>
	</div>



	<div class="top-users bets" *ngIf="state=='b'">
		<div class="user" *ngFor="let u of core.topSUsers" [class.me]="core.auth && u.uid==core.user.uid">
			<button class="show" (click)="getUserInfo(u.uid);mode = 'h'">
				<img src="{{ u.avatar || '/assets/placeholder_avatar_dark.png' }}"/>
				<span>Show</span>
			</button>
			<div class="info">
				<span class="stats">
					<span class="name">{{ u.username }}</span>
					<span class="right"> ${{ u.balance | number:'1.2-2' }}</span> Balance
					<ng-container *ngIf="(+u.balance + +u.total_unsettled - 1000) as net">
						<small class="rate" *ngIf="net>0">${{ net | number:'1.2-2' }} Net Gain</small>
						<small class="rate loss" *ngIf="net<0">${{ -net | number:'1.2-2' }} Net Loss</small>
					</ng-container>
				</span>
				<span class="ranks">
					Rank:
					<span class="rank">{{ u.bet_rank }}</span>
					<small class="score" *ngIf="+u.total_unsettled"> (unsettled: ${{ u.total_unsettled | number:'1.2-2' }})</small>
				</span>
			</div>
		</div>

		<ng-container *ngIf="core.auth && core.user as u">
			<div class="seperator"><i class="material-icons">more_vert</i></div>
			<div class="user me">
				<button class="show" (click)="getUserInfo(u.uid);mode = 'h'">
					<img src="{{ u.avatar || '/assets/placeholder_avatar_dark.png' }}"/>
					<span>Show</span>
				</button>
				<div class="info">
					<span class="stats">
						<span class="name">{{ u.username }}</span>
						<span class="right"> ${{ u.balance | number:'1.2-2' }}</span> Balance
						<ng-container *ngIf="(+u.balance + +u.total_unsettled - 1000) as net">
							<small class="rate" *ngIf="net>0">${{ net | number:'1.2-2' }} Net Gain</small>
							<small class="rate loss" *ngIf="net<0">${{ -net | number:'1.2-2' }} Net Loss</small>
						</ng-container>
					</span>
					<span class="ranks">
						Rank:
						<span class="rank">{{ u.bet_rank }}</span>
						<small class="score" *ngIf="+u.total_unsettled"> (unsettled: ${{ u.total_unsettled | number:'1.2-2' }})</small>
					</span>
				</div>
			</div>
		</ng-container>

		<div class="note">
			<span>Total Users: <span>{{ core.totalUsers }}</span></span>
			<span>Participants: <span class="lime">{{ core.bettors }}</span></span>
		</div>


	</div>


</ng-container>
