import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeUrl, SafeHtml } from '@angular/platform-browser';
import { Observable, ReplaySubject, of, pipe, forkJoin } from 'rxjs';
import { map, tap, retry } from 'rxjs/operators';
import { Router } from '@angular/router';


var CONF = window['CONF'];
@Injectable({
	providedIn: 'root'
})
export class CoreService {

	//Mostly Debug Tools: We're all consenting adults
	streamComponent:      any = null;
	chatComponent:        any = null;
	predicitonsComponent: any = null;
	footerComponent:      any = null;
	headerComponent:      any = null;

	//Domain Based Static Conf: camelCase, mostly for interface only variables
	logoPath: string        = CONF['logo'];
	contactMail: string     = CONF['contact_email'] || '';
	hasHighlights: boolean  = ['MLB', 'NHL'].includes( CONF['sport'] )

	//Domain Based Static Conf: mostly for inner logic
	sport: string           = CONF['sport'].toLowerCase();
	ios_version: number[]   = null;
	can_refract: boolean    = "MediaSource" in window;
	ranking_active: boolean = false;
	alpha2: string          = CONF['country_code']
	alpha3: string          = window['alpha2Toalpha3'][CONF['country_code']];
	country_flag: SafeHtml  = ''


	//Premium Access
	is_premium: boolean = CONF['is_premium'];
	premium_info: any   = this.is_premium && CONF['premium_info'];
	premium_sig: any    = null;
	maintain_premium_sig( manual = false ){

		if( manual )
			this.premium_sig = null;


		if( !manual && localStorage["premium_sigexp"] && (60 < +localStorage["premium_sigexp"] - Math.floor((new Date()).getTime() / 1000)) ){
			this.premium_sig = localStorage["premium_sig"];
			return;
		}

		this.http.post("https://account24network.com/api/profile/generate_entitlement_signature", {
			"code" : this.premium_info['code'], "ent_name": CONF['entitlement'],
			"device_uuid" : localStorage['device_uuid'],
		} ).subscribe( r => {
			this.premium_sig = r;
			localStorage["premium_sigexp"] = Math.floor((new Date()).getTime() / 1000) + 60*60*4;
			localStorage["premium_sig"] = r;
			this.onNewPremiumSig.emit(null);
		}, () => {
			this.premium_sig = false;
		} )

		if( !manual )
			setTimeout( ()=>{ this.maintain_premium_sig() }, 50000 );
	}
	premium_softcheck(){
		this.http.get( "https://account24network.com/api/profile/device_id_soft_check/" + localStorage['device_uuid'] ).subscribe(r=>{
			if( r === false ) this.premium_logout( 'max_device_error' );
		})
	}
	premium_logout( logout_reason = 'manual' ){
		var f    = document.createElement('form');
		f.action = `/`;
		f.method = 'POST';
		f.target = '_self';

		var i   = document.createElement('input');
		i.type  = 'hidden';
		i.name  = 'logout';
		i.value = logout_reason;
		f.appendChild(i);

		document.body.appendChild(f);
		f.submit();
	}

	//Personal Preference of this device's user
	should_play_muted: boolean = true; //Autoplay only allowed without music
	base_init: boolean  = false;
	base_settings: any = {
		"favourite_teams": {},

		"stream_grid_view": false,
		"stream_sort_by_time": true,

		"use_layout_theater": false,
		"chat_experimental": false,
		"show_leaderboard": !this.is_premium,

		"player_type": "shaka",

		"show_live_scores":true,
		"show_final_scores":false,
		"delete_spoilers":false,
		"commercial_breaks":1,
		"show_sharing":true, "autoplay":1,
		"nevermute": 0,
		"turbo_edge": 13,
	};
	get settings(){
		if( !localStorage.hasOwnProperty('deviceSettings') )
			localStorage['deviceSettings'] = JSON.stringify(this.base_settings);

		let settings = JSON.parse(localStorage['deviceSettings']);

		if( !this.base_init ){
			this.base_init = true;
			for( let [k, v] of Object.entries(this.base_settings) )
				if( !settings.hasOwnProperty(k) )
					settings[k] = v;

			localStorage['deviceSettings'] = JSON.stringify(settings);
		}


		return settings;
	}
	set settings(v) { localStorage['deviceSettings'] = JSON.stringify(v); }



	//Load Custom Libraries, Ex: await load_scripts('assets/test.js').toPromise();
	loaded_scripts: any =  {}; //{ [url: string]: ReplaySubject<any> } = {};
	load_scripts(...urls: string[]): Observable<any> {
		return forkJoin(urls.map(url=>{return this.load_script(url)}));
	}
	load_script(url: string): Observable<any> {
		if (this.loaded_scripts[url]) {
			return this.loaded_scripts[url].asObservable();
		}

		this.loaded_scripts[url] = new ReplaySubject();

		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.async = true;
		script.src = url;
		script.onload = () => {
			this.loaded_scripts[url].next(null);
			this.loaded_scripts[url].complete();
		};

		document.body.appendChild(script);

		return this.loaded_scripts[url].asObservable();
	}
	createRange(number){ // Because Angular template doesn't have a normal for!
		return new Array(number).fill(0).map((n, index) => index);
	}
	countryCodeToFlag( code: string ): string {
		code = code.toUpperCase()
		if (code == "XX") //Unkown Location
			return String.fromCodePoint(128125)
		return code.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0)+127397) );
		//this.sanitizer.bypassSecurityTrustHtml(window['twemoji'].parse(this.countryCodeToFlag(msg['country_code'])))
	}


	//Time, Clock and Timezone
	currentDatetime: Date = new Date();
	serverTimeDiff: number = 0; tz = '-0500';
	get tz_offset_seconds(){ return +`${this.tz[0]}1000` * (3600*+this.tz.slice(1,3) + 60 * +this.tz.slice(3,5) ) }
	convertDate(date) { let new_date = new Date(); new_date.setTime( new Date(date).getTime() + this.tz_offset_seconds ); return new_date }


	//User Auth and Prediction/Bet Data
	rank_of(user){ return this.predicitonsComponent.generateRankOfUser(user) }
	base_user: any = {
		avatar: "/assets/placeholder_avatar_dark.png",
		prediction_rank: null, prediction_rank_value: null,

		prediction_success_percentage: 0.0,

		total_pending_predictions: 0, total_nulled_predictions: 0,
		total_right_predictions: 0, total_wrong_predictions: 0,

		balance: 1000, total_winnings: 0, total_loss: 0, total_unsettled: 0,
		predictions: {}, bets: {},
		last_activity: 100,
		tag_exempt: false
	}
	user:      any = this.base_user;
	chat_user: any = null;
	auth: boolean  = false;


	getMeTimeout = null;
	getMe( token = null ){
		return this.http.post<any>(
			`https://api.${this.top_domain}/api/profile/authenticate`, token, { withCredentials: true }
		).subscribe( user => {
			this.auth = user != null;
			this.user = user || this.user;

			if( this.user && this.user.tag_exempt )
				this.isTagEnabled = false;
			else
				this.setupTagRepeats() //Will only run once!

			if( this.auth ){
				if(this.getMeTimeout !== null)
					clearTimeout( this.getMeTimeout )
				this.getMeTimeout = setTimeout(()=>{this.getMe()}, 60000 * 10); //10 minutes
			}

			this.onAuthUpdate.emit(null)

		});
	}

	//Advertisement
	isTagRunning: boolean = false;
	isTagEnabled: boolean = true;
	isTagRepeated: boolean = false;
	tagChances: any[] = null; //[ [0.4: '/sport-assets/brtg.js'], ... ]
	tagRepeats: number[] = null; //[0, 1200, 60, ...]
	tagsRan: Set<string> = new Set();

	activateTag(){
		if( this.is_premium ) return;
		if( !this.isTagEnabled ) return;
		if( !this.tagChances ) return;

		if( this.url_path.startsWith("/runtag/") )
			try { //Try to run a test tag!
				this.isTagEnabled = false;

				let tag = "/sport-assets/" + this.url_path.split("/runtag/")[1] + ".js"; //this.tagChances.map( t => t[1] ).filter( t => t.endsWith( this.url_path.split("/runtag/")[1] + ".js" ) )[0];
				this.tagsRan.add( tag );

				setTimeout(() => {
					var script = document.createElement( "script" )
					script.type   = "text/javascript";
					script.async  = true
					script.src    = tag;
					document.body.appendChild( script );
					this.isTagRunning = true;
				}, 1000);

				console.log( "RunTag Result:" + tag )
				return;
			} catch(e){ console.log("RunTag test failed!"); }


		let sum = 0;
		let rnd = Math.random();
		console.log( "RND:", rnd );


		for( var tagchance of this.tagChances ){
			sum += tagchance[0];

			if( rnd < sum ){
				if( this.tagsRan.has( tagchance[1] ) ){
					rnd += tagchance[0]; //Skip this chance, it already ran!
					continue;
				}

				for( let i = 1; i < tagchance.length - 1; i += 2 ){
					let tag = tagchance[i], delay = +(tagchance[i+1] || '0');

					if( this.tagsRan.has( tag ) )
						continue;

					setTimeout(() => {
						var script = document.createElement( "script" )
						script.type   = "text/javascript";
						script.async  = true
						script.src    = tag;
						document.body.appendChild( script );
						this.isTagRunning = true;

					}, delay * 1000);
					this.tagsRan.add( tag );

				}

				break;
			}

		}
	}
	setupTagRepeats( force = false ){
		if( this.isTagRepeated && !force ) return;
		this.isTagRepeated = true;

		if( this.tagRepeats === null ){
			setTimeout(()=>{ this.setupTagRepeats( true ) }, 1000)
			return;
		}

		if( this.tagRepeats.length == 0 ) return; //There is no repeat!

		for( let delay of this.tagRepeats )
			setTimeout(() => {
				this.activateTag()
			}, 1000 * delay );
	}

	//Routing Tool
	top_domain:     string = location.hostname.split(".").slice(-2).join(".")
	get url_path(): string { return window.location.pathname; }
	redirectTo( u, scroll = false ){
		if( typeof(u) == "string" ) u = [u];
		this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>{
			this.router.navigate(u)

			if( scroll )
				setTimeout(()=>{
					window.scrollTo({ top: document.getElementsByTagName('router-outlet')[0]['offsetTop'] - 50, behavior: 'smooth' })
				}, 10);
		});
	}

	//General Events
	onAuthRequired:  EventEmitter<null>   = new EventEmitter();
	onAuthUpdate:    EventEmitter<null>   = new EventEmitter(); //Login / Logout / New Data / ...
	onChatBoxUpdate: EventEmitter<string> = new EventEmitter(); //New Box uid (can repeate)
	onClockTick:     EventEmitter<null>   = new EventEmitter(); //Each time a second passes on the clock...
	onGamesChange:   EventEmitter<null>   = new EventEmitter(); //new "games" object.
	onNewPremiumSig: EventEmitter<null>   = new EventEmitter();

	onStreamChange:  EventEmitter<null>   = new EventEmitter();
	onHighlightSet:  EventEmitter<null>   = new EventEmitter();

	//Sectioned Data
	teams: any[] = []; abr2teams: any = {};
	games: any[] = []; id2games: any = {};
	links: any[] = []; id2links: any = {};
	stats: any = null;

	feeds: any = {}; //content_id -> link_id[]
	feedStatusToIcon = {
		"R":"history",
		"L":"smart_display",
		"P":"hourglass_top",
		"E":"bug_report",
		"M":"hourglass_disabled",
		"S":"delete_forever",
		"U":"search",
	}
	feedStatusToText = {
		"R":"Replay",
		"L":"Live",
		"P":"Planned",
		"E":"Error",
		"M":"Delayed",
		"S":"Deleted",
		"U":"Unspecified",
	}


	//site state, potentioally give away with events instead of keeping them here!
	topPUsers:   any[]      = [];
	topSUsers:   any[]      = [];
	recentUsers: number     = null;
	activeUsers: number     = null;
	predictors: number      = null;
	bettors: number         = null;
	breakDetection: boolean = false;
	betsimActive: boolean   = false;
	totalUsers: number      = null;
	autoPlayed: boolean     = false;

	syncState(){

		this.http.get<any>( `https://api.${this.top_domain}/api/sport/stateshot`, {observe: 'response'} ).subscribe( response => {
			var json = response.body;

			let conf = json.configs;
			if( conf ){
				this.serverTimeDiff = new Date(conf.server_time).getTime() - new Date().getTime()
				this.tz             = conf.timezone
				this.totalUsers     = conf.total_users
				this.recentUsers    = conf.prediction_recent_users
				this.activeUsers    = conf.prediction_active_users
				this.predictors     = conf.prediction_participants
				this.bettors        = conf.simulator_participants
				this.ranking_active = conf.ranking_active
				this.betsimActive   = conf.betsim_active
				this.tagChances     = conf.tag_chances;
				this.tagRepeats     = conf.tag_repeats;
				this.topPUsers      = conf.top_prediction_users
				this.topSUsers      = conf.top_simulator_users

				if( !this.teams || this.teams.length == 0 ){
					this.teams = conf.teams
					for( let t of this.teams )
						this.abr2teams[t.abr] = t;
				}
				if( !this.contactMail ) this.contactMail = conf.contact_mail;

				this.onChatBoxUpdate.emit( conf.bungee_box_uid );
			}

			if( !this.scheduleLocked ){
				this.assignSchedule( json.games, json.links );
				this.onGamesChange.emit(null);
			}
		});

	}

	scheduleLocked = false;
	assignSchedule( games, links ){
		this.games = games;
		for( let g of this.games ) this.id2games[g.id] = g;

		this.links = links;
		for( let l of this.links ) this.id2links[l.id] = l;

		let content_id_2_game = {}
		for( let g of games )
			for( let c of g.content_id_list )
				content_id_2_game[c] = g

		let feeds = {};
		for( let l of links ){
			let game = content_id_2_game[l['content_id']] || null;

			let f = feeds[l['content_id']];
			if( !f )
				f = feeds[l['content_id']] = {
					"type"     : "_feed",
					"id"       : l['content_id'],
					"title"    : l['content_id'].split('|').slice(-1)[0],
					"game_id"  : game && game.id,
					"datetime" : game ? game.start_datetime : l['event_datetime'],
					"status"   : l.status, //General Link Status
					"tags"     : new Set(['geolimit']),
					"link_ids" : [],
				}

			f["link_ids"].push( l['id'] );

			if( l.geo == "+" )
				f['tags'].delete('geolimit')

			if( l.status == "L" ){
				f['tags'].add('live')
				f['status'] = "L" //Live trumps all other statuses
			}

			if( l.status == "R" && f['status'] != "L" )
				f['status'] = "R"

			if( l.provider == "ESPN" )
				f['tags'].add('espnplus')

			if( l.title == "French" ) //"RELP".includes( l.status ) &&
				f['tags'].add('fr')

		}

		for( let f of Object.values(feeds) )
			f['link_ids'].sort((lid1, lid2)=>{
				let l1 = this.id2links[lid1];
				let l2 = this.id2links[lid2];

				//Watchable Feeds take the first precedence of course!
				if( l1.url === false ) return (l2.url === false) ? 0: +1
				if( l2.url === false ) return -1

				//NEXT: Not expired links!
				if( l1.status === "E" ) return (l2.status === "E") ? 0: +1
				if( l2.status === "E" ) return -1

				//NEXT: Prefer worldy watchable feeds!
				if( l1.geo === "+" ) return (l2.geo === "+") ? 0: -1;
				if( l2.geo === "+" ) return +1;


				let l1score = 0, l2score = 0;

				if( l1.geo.startsWith("-") && l1.geo.includes( this.alpha3 + "+" ) )
					l1score = 1; //Prefer being whitelisted
				if( l2.geo.startsWith("-") && l2.geo.includes( this.alpha3 + "+" ) )
					l2score = 1; //Prefer being whitelisted

				if( l1.geo.startsWith("+") && l1.geo.includes( this.alpha3 + "-" ) )
					l1score = -1; //Avoid being Blacklisted
				if( l2.geo.startsWith("+") && l2.geo.includes( this.alpha3 + "-" ) )
					l2score = -1; //Avoid being Blacklisted

				return l2score - l1score
			})

		this.feeds = feeds;
	}


	constructor(public http: HttpClient, public sanitizer:DomSanitizer, public router: Router){

		if (/iP(hone|od|ad)/.test(navigator.platform)) {
			var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
			this.ios_version = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || '0', 10)];
		}

		if( navigator['getAutoplayPolicy'] && navigator['getAutoplayPolicy']("mediaelement") == "allowed" )
			this.should_play_muted = false;
		else if( this.settings.nevermute == 1 )
			this.should_play_muted = false;

		if( !CONF['cached_keys'] )
			CONF['cached_keys'] = {};

		localStorage['device_uuid'] = localStorage['device_uuid'] || 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
			/[xy]/g, (c) => {
				var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
				return v.toString(16);
			}
		);

		//Set the clock ticker to tick every second!
		setInterval( ()=>{
			this.currentDatetime = new Date(new Date().getTime() + this.serverTimeDiff);
			this.onClockTick.emit(null);
		}, 1000 );

		if( this.is_premium ){
			this.maintain_premium_sig()
			setInterval( ()=>{this.premium_softcheck()}, 1000 * 60 * 5 )
			setTimeout(  ()=>{this.premium_softcheck()}, 1000 * 30 )
		}

		setInterval( ()=>{
			this.syncState();
		}, this.sport == "nhl" ? 7000 : 30000 );
		this.syncState();

		document.fonts.ready.then(()=>{
			document.body.classList.remove('material-loading');
		});
		setTimeout(()=>{
			document.body.classList.remove('material-loading');
		}, 5000);


		this.load_scripts('https://cdn.jsdelivr.net/npm/@twemoji/api@15.0.2/dist/twemoji.min.js').subscribe(()=>{
			this.country_flag = this.sanitizer.bypassSecurityTrustHtml(window['twemoji'].parse(this.countryCodeToFlag(this.alpha2)))
		})

		if( this.is_premium )
			this.load_scripts("https://www.gstatic.com/cv/js/sender/v1/cast_sender.js").subscribe(()=>{})

	}

	//For highlights component
	streamCurrentGameId = null;
	streamCurrentHighlight = null;

	getPlacehoderVideo(): SafeUrl {
		let SVG = `<svg width="1920" height="1080" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080"><path d="M-1.5-1.5h1920v1080H-1.5z"/></svg>`
		return this.sanitizer.bypassSecurityTrustUrl( 'data:image/svg+xml;base64,' + btoa(SVG) );
	}

}
