<header class="my-rank" [ngClass]="rank.title">

	<span class="title">
		<span class="material-icons">{{ rank.icon }}</span>
		<span class="text">{{ rank.title }}</span>
	</span>

	<small class="score">
		<span class="bold-text">{{ core.user.prediction_success_percentage | number:'0.0-2' }}%</span><br/>
		( <span class="good-text">{{ core.user.total_right_predictions }}</span>
		to <span class="bad-text">{{ core.user.total_wrong_predictions }}</span>
		/ <span class="nutral-text">{{ core.user.total_nulled_predictions }}</span> )
	</small>

	<span class="number">
		<span class="rank">{{ rank.calibration_left <= 0 ? core.user.prediction_rank : '?' }}</span> of {{ core.totalUsers }}
	</span>

	<span *ngIf="rank.calibration_left > 0" (click)="rankingRulesShown=true" class="info uncalibrated">
		<i class="material-icons">info</i>
		<span class="rank">{{ rank.calibration_left }}</span> points <br/>
		left to get your rank.
	</span>

	<span *ngIf="rank.calibration_left <= 0" (click)="rankingRulesShown=true" class="info">
		<i class="material-icons">info</i>More Info?
	</span>

</header>

<div class="prediction-wrapper" (scroll)="onScroll($event)">

	<ng-container *ngFor="let game of core.games">

		<div class="datetime-sep clock" *ngIf="timeBefore == game.id" id="clock_element">
			<span class="time-text">Now {{ core.currentDatetime | date:'HH:mm:ss':core.tz }} (ET)</span>
		</div>

		<div class="datetime-sep" *ngIf="dateBefore.has(game.id)" [ngClass]="'status_' + game.status">
			<span class="date-text">{{ game.start_datetime | date:'MMM dd':core.tz }}</span>
		</div>

		<div class="game" [ngClass]="'status_' + game.status"
				[class.overlap]="sameBefore.has(game.id)"
				[class.predicted]="core.user.predictions.hasOwnProperty(game.id)" [class.outdated]="game._outdated"

				[class.home_selected]="amounts[game.id] > 0" [class.away_selected]="amounts[game.id] < 0"

				[class.predict-red]="abs(amounts[game.id]) == 100"   [class.predict-orange]="abs(amounts[game.id]) == 75"
				[class.predict-yellow]="abs(amounts[game.id]) == 50" [class.predict-green]="abs(amounts[game.id]) == 25"
				[class.predictable]="game.predictable && game.status == 'P' && !core.user.predictions.hasOwnProperty(game.id)"
				[class.hide-result]="core.settings.delete_spoilers"
				[class.spoilable]="game.status == 'I' ? !core.settings.show_live_scores : !core.settings.show_final_scores">

			<span class="datetime">{{ game.start_datetime | date:'MM/dd HH:mm':core.tz }} (ET)</span>
			<span class="away image" [class.winner]="game.winner == 'A'" [class.predicted]="core.user.predictions[game.id] < 0"
				(click)="affirmSide(game.id, 'A')">
				<input value='away' type="image" [class.beating]="beating.has(game.id)" src="/sport-assets/teams/{{ game.away_abr }}.svg"/>
				<div class="checkbox"><span *ngIf="amounts[game.id] < 0" class="text">{{ -amounts[game.id]/25 }}/4 Points</span><span class="bar" [style.right.%]="100 + amounts[game.id]"></span></div>
				<small class="predicted-points">{{ -core.user.predictions[game.id] }}/4</small>
			</span>
			<span class="home image" [class.winner]="game.winner == 'H'" [class.predicted]="core.user.predictions[game.id] > 0"
				(click)="affirmSide(game.id, 'H')">
				<input value='home' type="image" [class.beating]="beating.has(game.id)" src="/sport-assets/teams/{{ game.home_abr }}.svg"/>
				<div class="checkbox"><span *ngIf="amounts[game.id] > 0" class="text">{{ +amounts[game.id]/25 }}/4 Points</span><span class="bar" [style.right.%]="100 - amounts[game.id]"></span></div>
				<small class="predicted-points">{{ +core.user.predictions[game.id] }}/4</small>
			</span>

			<ng-container [ngSwitch]="game.status">

				<ng-container *ngSwitchCase="'P'">
					<ng-container *ngIf="game.predictable && !core.user.predictions.hasOwnProperty(game.id); else cantPredictBlock">
						<button class="predict" (click)="commitPrediction( game.id )" [class.disabled]="core.auth && !amounts[game.id]">
							PREDICT <span class="rotating material-icons" *ngIf="loading.has(game.id)">autorenew</span>
						</button>
					</ng-container>

					<ng-template #cantPredictBlock>
						<div class="predict" *ngIf="core.user.predictions.hasOwnProperty(game.id)">
							PREDICTED <b class="percentage">{{ abs(core.user.predictions[game.id]) }}/4</b>
						</div>
						<div class="predict" *ngIf="!core.user.predictions.hasOwnProperty(game.id)">
							NOT FOR PREDICTING
						</div>
					</ng-template>
				</ng-container>

				<ng-container *ngSwitchCase="'I'">
					<div class="predict" *ngIf="core.settings.show_live_scores || !core.settings.delete_spoilers; else noScoreBlock">
						<i class="material-icons">visibility</i><span class="score">{{ game.away_score }}</span>
						- LIVE -
						<i class="material-icons">visibility</i><span class="score">{{ game.home_score }}</span>
					</div>
					<ng-template #noScoreBlock><div class="predict">LIVE</div></ng-template>
				</ng-container>

				<ng-container *ngSwitchCase="'F'">
					<div class="predict" *ngIf="core.settings.show_final_scores || !core.settings.delete_spoilers; else noScoreBlock">
						<i class="material-icons">visibility</i><span class="score">{{ game.away_score }}</span>
						- Game Ended -
						<i class="material-icons">visibility</i><span class="score">{{ game.home_score }}</span>
					</div>
					<ng-template #noScoreBlock><div class="predict">Game Ended</div></ng-template>
				</ng-container>

				<div *ngSwitchCase="'N'" class="predict">
					Postponed / Canceled
				</div>

			</ng-container>

		</div>

	</ng-container>

	<div class="datetime-sep" *ngIf="!timeBefore">
		<span class="time-text">Now {{ core.currentDatetime | date:'HH:mm:ss':core.tz }} (ET)</span>
	</div>

</div>

<div class="rankinfo-panel" #rankinfo_panel [class.active]="rankingRulesShown" (click)="close_rankinfo_panel($event, rankinfo_panel)">
	<div class="inner-modal rankinfo">
		<button class="close material-icons" (click)="rankingRulesShown = false;">close</button>
		<header>
			<h1>Prediction Competition</h1>
			By predicting in our site, you'll be competing with other users and recieve ranks and titles,<br/>
			Each user's title will be computed using <b>3</b> different variables in 3 different steps.
		</header>

		<header class="phase-info">
			The <b>C</b> Variable, <small>Total amount of predictions</small><br/>
			<small>These titles are given to users soley based on their activity, regadless of the outcomes.</small>
		</header>
		<div class="phase">
			<button>
				<i class="material-icons">nature_people</i>
				<span>Commoner</span>
				<small>
					0 &le; C &lt; 10
				</small>
			</button>
			<button>
				<i class="material-icons">child_care</i>
				<span>Newbie</span>
				<small>
					10 &le; C &lt; 25
				</small>
			</button>
		</div>

		<header class="phase-info">
			The <b>V</b> Variable, <small>Algorithm's Rank Value</small><br/>
			<small>
				These titles are given to users based on the value assigned to them by our ranking algorithm.
			</small>
		</header>
		<div class="phase">
			<button>
				<i class="material-icons">school</i>
				<span>Graduate</span>
				<small>
					5 &le; V &lt; 40
				</small>
			</button>
			<button>
				<i class="material-icons">cake</i>
				<span>PieceOfCake</span>
				<small>
					40 &le; V
				</small>
			</button>
		</div>

		<header class="phase-info">
			The <b>R</b> Variable, <small>The Actual rank number</small><br/>
			<small>These are the titles given only to the top 20 users.</small>
		</header>
		<div class="phase">
			<button class="Apprentice">
				<i class="material-icons">face</i>
				<span class="title">Apprentice</span>
				<small>
					20 &le; R &lt; 10
				</small>
			</button>
			<button class="Masterful">
				<span class="icons">
					<i class="material-icons">money</i>
					<i class="material-icons">looks_two</i>
					<i class="material-icons">looks_3</i>
				</span>
				<span class="title">Masterful</span>
				<small>
					10 &le; R &le; 2
				</small>
			</button>
			<button class="Oracle">
				<i class="material-icons">visibility</i>
				<span class="title">Oracle</span>
				<small>
					Number One
				</small>
			</button>
		</div>

		<footer>
			<p>
				You can view the top 20 users, by using the "Show Rankings" Button on the top corner of chat.<br/>
				Also, each user's stats can be seen in Chat, via a "rank" link next to their name.
			</p>
		</footer>
	</div>
</div>
