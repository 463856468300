import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'moneyPipe' })
export class MoneyPipe implements PipeTransform {
	transform(x: number, fixed: number = 2): string {
		var parts = (+x).toFixed(fixed).toString().split(".");
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return parts.join(".");
	}
}


@Pipe({ name: 'fileSize' })
export class FileSizePipe implements PipeTransform {

	private units = [
		'bytes',
		'KB',
		'MB',
		'GB',
		'TB',
		'PB'
	];

	transform(bytes: number = 0, precision: number = 2 ) : string {
		if ( isNaN( parseFloat( String(bytes) )) || ! isFinite( bytes ) ) return '?';

		let unit = 0;

		while ( bytes >= 1024 ) {
			bytes /= 1024; unit ++;
		}

		return bytes.toFixed( + precision ) + '' + this.units[ unit ];
	}
}
