import { Component } from '@angular/core';
import { CoreService } from '../core.service'


@Component({
	selector: 'app-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
	constructor( public core: CoreService ){}

	navigator = navigator;

	get settings()  {
		try{ return {...this.core.base_settings, ...JSON.parse(localStorage['deviceSettings'])};}
		catch(e) { return this.settings = this.core.base_settings; }
	}
	set settings(v) { this.core.settings = v; }

	revert_settings(){
		this.settings = this.core.base_settings;
	}


	toggleSettings(name, force = null){
		let settings = this.settings;

		if( force === null )
			settings[name] = !(settings[name] || false)
		else
			settings[name] = force;

		this.settings = settings;
	}

	setTheme(name){
		let set = document.documentElement.classList

		if( this.settings['theme_name'] )
			set.remove( "theme-" + this.settings['theme_name'] )

		set.add("theme-" + name)
		this.toggleSettings('theme_name', name);
	}

	cycleStars(team_abbr){
		let settings = this.settings;
		let score = settings['favourite_teams'][team_abbr] || 0;
		score = (score + 1)%6;

		if( score == 0 )
			delete settings['favourite_teams'][team_abbr]
		else
			settings['favourite_teams'][team_abbr] = score

		this.settings = settings
	}

	ngOnInit() {

	}

}
