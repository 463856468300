<header>
	<p>
		Here you can view the Highlights of the game you're currently watching,<br/>
		Changing the stream will automatically load the relevent Highlights below.
	</p>
</header>


<div *ngIf="!highlights || highlights.length == 0" class="highlight-wrapper">
	<div class="highlight">
		<div class="images">
			<img [src]="core.getPlacehoderVideo()" class="placeholder" alt=""/>
		</div>
		<div class="overlay">
			<header class="title">
				{{ core.streamCurrentGameId ? (
						( highlights && highlights.length == 0 ) ? 'Nothing to Load' : 'Loading...'
					) : 'No Games To Load' }}
			</header>
			<p *ngIf="core.streamCurrentGameId">
				{{ ( highlights && highlights.length == 0 ) ? 'Game Probably has no Highlights Yet' : "Loading the current game's Highlights..." }}
			</p>
			<p *ngIf="!core.streamCurrentGameId">There is nothing to load.</p>
		</div>
	</div>
</div>


<div *ngIf="highlights && highlights.length > 0" class="highlight-wrapper">
	<div *ngFor="let item of highlights" class="highlight" [ngClass]="item.content">
		<div class="images">
			<img [src]="core.getPlacehoderVideo()" class="placeholder" alt=""/>
			<img [src]="item.image_src" alt=""/>
		</div>
		<div class="overlay">
			<header class="title">
				{{ item.title }}
				<button (click)="showVideo(item)">
					<span class="duration">{{ item.duration }}</span>
					<i class="material-icons">play_circle_filled</i>
				</button>
			</header>
			<p>{{ item.description }}</p>
		</div>
	</div>
</div>
